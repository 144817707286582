export default {
  MuiTableRow: {
    root: {
      "&$selected": {
        backgroundColor: 'rgb(228, 228, 230, 0.43)',
        "&:hover": {
          backgroundColor: 'rgb(228, 228, 230, 0.43)',
        }
      },
    },
    selected: {},
  },
  MuiList: {
    padding: {
      paddingBottom: 0,
    }
  },
  MuiListItem: {
    root: {
      "&$selected": {
        backgroundColor: 'rgb(228, 228, 230, 0.43)',
        "&:hover": {
          backgroundColor: 'rgb(228, 228, 230, 0.43)',
        }
      },
    },
    selected: {},
  },
  MuiTableContainer: {
    root: {
      '&::-webkit-scrollbar': {
        width: '16px',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: '#D8D8D8',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#999999',
        borderRadius: '6.5px',
        border: '2px solid transparent',
        backgroundClip: 'content-box',
      }
    }
  },
  MuiFormControlLabel: {
    label: {
      fontWeight: 300,
      color: '#222222',
      letterSpacing: '-0.09px',
      fontSize: '14px',
      marginLeft: -5,
    }
  },
  MuiOutlinedInput: {
    root: {
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#e4e4e6'
      }
    },
    input: {
      "&::placeholder": {
        color: '#D8D8D8',
        opacity: 1,
        fontSize: 14,
        fontWeight: 300,
        letterSpacing: 0,
        fontFamily: 'Roboto',
      }
    }
  },
  MuiSelect: {
    iconOutlined: {
      width: '1.2em',
      height: '1.2em'
    }
  },
  MuiTablePagination: {
    caption: {
      color: '#4A4A4B',
      opacity: 1
    }
  }
}
