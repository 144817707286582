import { reset } from 'redux-form';
import axiosWithToken from '../../utili/axiosWithToken';
import { AddErrorAlert, AddSuccessAlert } from '../alert/alert';
const GET_SCHOOLS = 'GET_SCHOOLS';
const SELECT_SCHOOL = 'SELECT_SCHOOL';
const SET_SCHOOL_STATUS = 'SET_SCHOOL_STATUS';
const CREATE_SCHOOL = 'CREATE_SCHOOL';
const UPDATE_SCHOOL = 'UPDATE_SCHOOL';
const SCHOOL_SEARCHSTRING_CHANGE = 'SCHOOL_SEARCHSTRING_CHANGE';

const initialState = {
  SchoolList: [],
  TotalSchool: 0,
  ActiveSchool: null,
  searchString: null,
};
//reducer
export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_SCHOOLS:
      return { ...state, SchoolList: payload.rows, TotalSchool: payload.count };
    case SELECT_SCHOOL:
      return { ...state, ActiveSchool: payload };
    case SET_SCHOOL_STATUS:
      return {
        ...state,
        SchoolList: state.SchoolList.map((sch) => {
          if (sch._id === payload.schoolId) {
            sch.is_active = payload.status;
          }
          return sch;
        }),
      };
    case CREATE_SCHOOL:
      return { ...state, SchoolList: [payload, ...state.SchoolList] };
    case UPDATE_SCHOOL:
      return {
        ...state,
        ActiveSchool: null,
        SchoolList: [
          payload,
          ...state.SchoolList.filter((sch) => sch._id !== payload._id),
        ],
      };
    case SCHOOL_SEARCHSTRING_CHANGE:
      return { ...state, ActiveSchool: null, searchString: payload };

    default:
      return state;
  }
}

//actions
export const GetSchools = () => async (dispatch) => {
  try {
    const schools = await axiosWithToken.get('/schools');

    //console.log(schools.data);
    dispatch({
      type: GET_SCHOOLS,
      payload: schools.data,
    });
  } catch (error) {
    dispatch(AddErrorAlert(error));
  }
};

export const SelectSchool = (school) => {
  return {
    type: SELECT_SCHOOL,
    payload: school,
  };
};

export const SetSchoolStatus = (data) => async (dispatch) => {
  //data:schoolId, status

  try {
    const school = await axiosWithToken.post('/setSchoolStatus', data);

    dispatch({
      type: SET_SCHOOL_STATUS,
      payload: data,
    });

    dispatch(
      AddSuccessAlert(
        `${school.data.schoolName} has been ${
          data.status ? 'activated' : 'deactivated'
        }.`
      )
    );
  } catch (error) {
    //dispatch(AddErrorAlert(error));
  }
};

export const CreateSchool = (data) => async (dispatch) => {
  //data:schoolId, status

  try {
    const school = await axiosWithToken.post('/newAccount', data);

    dispatch({
      type: CREATE_SCHOOL,
      payload: school.data,
    });
    dispatch(reset('AccountNewForm'));
    dispatch(AddSuccessAlert(`${school.data.name} has been created.`));
  } catch (error) {
    //dispatch(AddErrorAlert(error));
  }
};

export const UpdateSchool = (data, fnCallback) => async (dispatch) => {
  //data:schoolId, status

  try {
    console.log(data);

    const school = await axiosWithToken.post('/updateAccount', data);

    dispatch({
      type: UPDATE_SCHOOL,
      payload: school.data,
    });
    dispatch(reset('AccountEditForm'));
    dispatch(AddSuccessAlert(`${school.data.name} has been updated.`));

    if (typeof fnCallback === 'function') fnCallback();
  } catch (error) {
    //dispatch(AddErrorAlert(error));
  }
};

export const schoolSearchStringChange = (searchString) => {
  return {
    type: SCHOOL_SEARCHSTRING_CHANGE,
    payload: searchString,
  };
};
