import React, { Component } from 'react';
import { connect } from 'react-redux';
import Nav from '../Nav';
import NavMobile from '../responsive/NavMobile';
import Header from './Header';
import HeaderMobile from '../responsive/HeaderMobile';
import Alert from '../Alert/Alert';
import ProfileEditor from '../Forms/ProfileEditor/ProfileEditor';
class MainLayout extends Component {
  userProfileModal = React.createRef()
  state = {
    pathname: window.location.pathname,
  };

  render() {
    const excludeStudentNavigationFromFromPaths = ['/settings', '/messages', '/notice', '/contact', '/report', '/admin']
    const today = new Date();
    const { isMobile, crest, schoolName } = this.props;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-1">
            {!isMobile ? (
              <div className="position-fixed h-100 leftMenu">
                <Nav
                  setUrl={this.setState.bind(this)}
                  pathname={this.state.pathname}
                  userProfileShow={()=>{
                    this.userProfileModal.current._show()
                  }}
                />
              </div>
            ) : (
              <NavMobile
                setUrl={this.setState.bind(this)}
                pathname={this.state.pathname}
              />
            )}
          </div>
          <div className="col-md-11 main-content" style={{ padding: 0 }}>
            <Alert />
            {!isMobile ? (
              <Header
                setUrl={this.setState.bind(this)}
                pathname={this.state.pathname}
                excludedPaths={excludeStudentNavigationFromFromPaths}
                schoolName={schoolName}
                crest={crest}
              />
            ) : (
                <div>
                  <HeaderMobile
                      schoolName={schoolName}
                      userProfileShow={()=>{
                        this.userProfileModal.current._show()
                      }}
                      avatar={this.props.avatar}
                  />
                </div>
            )}

            <div className="site-content">
              {this.props.children}
              <ProfileEditor ref={this.userProfileModal} />
            </div>
            <div className="site-footer center-item-veri-horiz">
              <div className="text-center text-darkgray">
                &copy; {today.getFullYear()} e-TELL. All Rights Reserved.{' '}
                <b>Terms of Use Privacy Policy</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  isMobile: state.responsive,
  schoolName: state.profile.currentUser.school_name,
  crest: state.profile.currentUser.Crest,
  avatar: state.profile.currentUser.Avatar
});
export default connect(mapStateToProps)(MainLayout);
