import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import {EmptyAvatarIcon} from '../Icons/EmptyAvatarIcon';

const styles = (theme) => ({
  main: {
    background: theme.primary.main,
    height: '60px',
    width: '100%',
  },
});

const HeaderMobile = (props) => {
  const { classes, schoolName, avatar, userProfileShow } = props;
  return (
    <div className={clsx(classes.main, 'header-mobile', 'w-100', 'fixed-top')}>
      <div></div>
      <h6>{schoolName ? schoolName : 'This is the College Name'}</h6>
        <div className="avatar-wrapper" onClick={userProfileShow}>
          {avatar && <img src={avatar} alt="Avatar" />}
          {!avatar && <EmptyAvatarIcon style={{width: '36px', height: '36px', background: '#fff'}} />}
        </div>
    </div>
  );
};

export default withStyles(styles)(withTheme(HeaderMobile));
