import React, { Component } from 'react';
import './Checkbox.scss';

export class Checkbox extends Component {
  render() {
    const { onChange, label, checked } = this.props;
    return (
      <div className="Checkbox" onClick={onChange}>
        <span
          className={`Checkbox-box text-info fa ${
            checked ? 'fa-check-square' : 'fa-square-o'
          }`}
        ></span>
        <small className="text-darkgray">{label}</small>
      </div>
    );
  }
}

export default Checkbox;
