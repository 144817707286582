import React from 'react';
import Modal from 'react-modal';
import './LoadingPage.scss';
import MainLayout from '../../components/Layout/MainLayout';
import withTheme from '@material-ui/core/styles/withTheme';

Modal.setAppElement('#LoadingPage');

export default withTheme(
    function LoadingPage({theme}) {
      return (
          <MainLayout>
            <Modal
                isOpen={true}
                className="Modal modal-loader"
                style={{
                  content: {
                    outline: 'none',
                  }
                }}
            >
              <div style={{borderTopColor: theme.primary.main, borderTopWith: 16}} className="loader"></div>
            </Modal>
          </MainLayout>
      );
    }
)
