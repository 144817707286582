import React from 'react';
import {makeStyles, withTheme} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  filled: {
    backgroundColor: theme.primary.main,
    borderColor: theme.primary.main,
    borderWidth: 2,
    '&:hover, &:focus, &:active, &:not(:disabled):not(.disabled):active, &:disabled': {
      borderColor: theme.primary.main,
      backgroundColor: theme.primary.main,
    },
    '&:not(:disabled):not(.disabled):active:focus': {
      boxShadow: `0 0 0 0.2em ${theme.primary.main + '33'}`
    },
    '&:focus': {
      boxShadow: 'none',
    }
  },
  outlined: {
    borderColor: theme.primary.main,
    borderWidth: 2
  }
}))

const BasicButton = props => {
  const {theme, className, filled = true, ...otherProps} = props;
  const styles = useStyles()
  const style = filled ? styles.filled : styles.outlined
  return (
      <button className={`btn ${filled ? 'btn-primary' : ''} ${style} ${className}`}
              {...otherProps}
      >{props.text ? props.text : props.children}</button>
  )
}

export default BasicButton;
