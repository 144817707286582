import React, {useCallback, useEffect, useState} from 'react';
import {UploadIcon} from '../../Icons/UploadIcon';
import {withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';

const UploadFiled = withStyles(
    (theme) => {
      const themeColor = theme.primary.main.substring(1, theme.primary.main.length);
      return ({
        uploadFieldWrapper: {
          color: theme.primary.main,
          borderRadius: 5,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'default',
          backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23${themeColor}' stroke-width='2' stroke-dasharray='6%2c 5' stroke-dashoffset='5' stroke-linecap='square'/%3e%3c/svg%3e")`,
        },
        thumbnail: {
          height: 200,
          width: 'auto',
          cursor: 'pointer'
        },
        active: {
          cursor: 'pointer',
        },
        imageFieldWrapper: {
          backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23${themeColor}' stroke-width='2' stroke-dasharray='6%2c 5' stroke-dashoffset='5' stroke-linecap='square'/%3e%3c/svg%3e")`,
          cursor: 'pointer'
        }
      })
    },
    { withTheme: true },
)(React.forwardRef((props, ref) => {
  const {
    file = false,
    alt, onFileUpload,
    src = null,
    classes,
    text,
    dataSubmitted = false,
    wrapperClassName = '',
    disabled,
    imageWrapperClassName = '',
    showImageBorder = false,
    ...otherProps
  } = props
  const [isUploading, toggleUpload] = useState(false)
  const [url, setUrl] = useState(null)
  const input = React.createRef()

  const handleUpload = useCallback(e => {
    const file = e.target.files[0];

    if (file) {
      toggleUpload(true)
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        toggleUpload(false)
        setUrl(reader.result)
        onFileUpload && onFileUpload(file);
      };
    }
  }, [src, isUploading])

  useEffect(() => {
    if(dataSubmitted && url) {
      setUrl(null)
    }
  }, [dataSubmitted, url])

  const triggerInput = useCallback(() => {
    input.current.click()
  }, [input])

  React.useImperativeHandle(ref, () => ({
    triggerInput() {
      input.current.click()
    }
  }));

  if(src || url) {
    return (
        <div className={clsx(showImageBorder ? classes.imageFieldWrapper : '', imageWrapperClassName)} onClick={triggerInput}>
          <img
              className={clsx("img-thumbnail", classes.thumbnail)}
              alt={alt || 'Image'}
              src={src || url}
          />
          <input
              ref={input}
              className="d-none"
              onChange={handleUpload}
              type="file"
              accept="image/*"
              disabled={disabled}
              {...otherProps}
          />
        </div>
    )
  } else return (
      <div
          onClick={triggerInput}
          className={clsx('text-center', classes.uploadFieldWrapper, wrapperClassName, !disabled ? classes.active : '')}
      >
        <div className="d-flex">
          <UploadIcon style={{width: 17, height: 17}}/>
          <span className="ml-2" hidden={file}>
            {!isUploading ? text || 'Upload File' : 'Uploading...'}
          </span>

          <input
              ref={input}
              className="d-none"
              onChange={handleUpload}
              type="file"
              accept="image/*"
              disabled={disabled}
              {...otherProps}
          />
        </div>
      </div>
  )
}))

export default UploadFiled;
