import React, { Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom';
import {Provider, useSelector} from 'react-redux';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import { unregister } from './registerServiceWorker';
import store from './store/store';
import RequireAuth from './utili/auth/RequireAuth';
import RequireAdmin from './utili/auth/RequireAdmin';
import { AUTH_USER } from './store/auth/authConstants';
import { setResponsive } from './store/responsive/setResponsive';
import MainLayout from './components/Layout/MainLayout';
import LoadingPage from './pages/LoadingPage/LoadingPage';
import NotFound from './pages/NotFound/NotFound';
import Login from './pages/Login/Login';
import { ThemeProvider } from '@material-ui/core';
import {ThemeVariants} from './MuiThemes';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {CHANGE_THEME} from './store/ui/uiConstants';
import {SET_USER_AVATAR} from './store/profile/profileConstants';
import EmailVerify from './pages/EmailVerify/EmailVerify';
import { S3_URL } from './config/keys';
import ScrollToTop from './components/ScrollToTop';
import ForgotPassword from "./pages/Login/ForgotPassword";

unregister();

const Main = React.lazy(() => import('./pages/Main'));
const Profile = React.lazy(() => import('./pages/Profile'));
const StudentDashboard = React.lazy(() =>
  import('./pages/Student/StudentDashboard/StudentDashboard')
);
const ContactDashboard = React.lazy(() =>
  import('./pages/Contact/ContactDashboard')
);
const MessagesDashboard = React.lazy(() =>
  import('./pages/Messages/MessageIndex')
);
const NoticeDashboard = React.lazy(() =>
  import('./pages/Notice/NoticeDashboard')
);
const ReportDashboard = React.lazy(() => import('./pages/Report/ReportIndex'));
const AdminDashboard = React.lazy(() => import('./pages/Admin/AdminDashboard'));

const token = sessionStorage.getItem('token');
//if we have token, consider the user ot be signed in
if (token) {
  //we need to update application state
  store.dispatch({ type: AUTH_USER });
  const avatar = sessionStorage.getItem('avatar')
  store.dispatch({ type: SET_USER_AVATAR, payload: avatar ? avatar : ''})
}

const InjectResponsiveHOC = ({ children }) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width:767px)');

  useEffect(() => {
    dispatch(setResponsive(isMobile));
  }, [isMobile]);

  return <React.Fragment>{children}</React.Fragment>;
};

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout {...props}>
        <Component {...props} />
      </Layout>
    )}
  />
);

const App = () => {
    const theme = sessionStorage.getItem('theme');
    if(theme) {
      const currentTheme = ThemeVariants.find(variant => variant._name === theme)
      currentTheme && store.dispatch({ type: CHANGE_THEME, payload: currentTheme})
    }
    const themeState = useSelector(store => store.ui.theme)
    return (
          <ThemeProvider theme={themeState}>
            <InjectResponsiveHOC>
              <Router>
                <div>
                  <Suspense fallback={<LoadingPage />}>
                    <Switch>
                      <Route path="/" exact component={Login} />
                      <Route path="/reset/:token" component={ForgotPassword} />
                      <Route path="/main">
                        <AppRoute layout={MainLayout} component={RequireAuth(Main)} />
                      </Route>
                      <Route path="/settings">
                        <AppRoute
                            layout={MainLayout}
                            component={RequireAuth(Profile)}
                        />
                      </Route>
                      <Route path="/student">
                        <AppRoute
                            layout={MainLayout}
                            component={RequireAuth(StudentDashboard)}
                        />
                      </Route>
                      <Route path="/messages">
                        <AppRoute
                            layout={MainLayout}
                            component={RequireAuth(MessagesDashboard)}
                        />
                      </Route>
                      <Route path="/notice">
                        <AppRoute
                            layout={MainLayout}
                            component={RequireAuth(NoticeDashboard)}
                        />
                      </Route>
                      <Route path="/contact">
                        <AppRoute
                            layout={MainLayout}
                            component={RequireAuth(ContactDashboard)}
                        />
                      </Route>
                      <Route path="/report">
                        <AppRoute
                            layout={MainLayout}
                            component={RequireAuth(ReportDashboard)}
                        />
                      </Route>
                      <Route path="/admin">
                        <AppRoute
                            layout={MainLayout}
                            component={RequireAdmin(AdminDashboard)}
                        />
                      </Route>
                      <Route path="/email-update/verify/:token" component={EmailVerify} />
                      <Route component={NotFound} />
                    </Switch>
                  </Suspense>
                </div>
                <ScrollToTop/>
              </Router>
            </InjectResponsiveHOC>
          </ThemeProvider>
    )
}

ReactDOM.render(<Provider store={store}><App/></Provider>, document.getElementById('root'));
