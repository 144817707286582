import { AUTH_USER, UNAUTH_USER } from './authConstants';

const initState = {
  authenticated: false,
  IsAdmin: false,
  permissions: [],
  role: '',
  _id: '',
};

export default (state = initState, action) => {
  switch (action.type) {
    case AUTH_USER:
      return {
        ...state,
        authenticated: true,
        IsAdmin: sessionStorage.getItem('IsAdmin'),
        permissions: sessionStorage.getItem('permissions')
            ? sessionStorage.getItem('permissions').split(',')
            : [],
        role: sessionStorage.getItem('role'),
        _id: sessionStorage.getItem('_id')
      };
    case UNAUTH_USER:
      return { ...state, authenticated: false };
    default:
      return state;
  }
};
