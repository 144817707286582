import key from '../config/keys';

export function CompleteUrl(url) {
  if (!url) return '';

  return `${key.S3_URL}/${url}`;
}

export function getUniqueListBy(arr, key) {
  return [...new Map(arr.map(item => [item[key], item])).values()]
}

export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}
