import logo from '../assets/images/logo_white.svg';
import studentIcon from '../assets/images/student_icon.svg';
import messageIcon from '../assets/images/message_icon.svg';
import noticeIcon from '../assets/images/notice_icon.svg';
import contactIcon from '../assets/images/contact_icon.svg';
import reportIcon from '../assets/images/report_icon.svg';
import adminIcon from '../assets/images/admin.svg';
import settingsIcon from '../assets/images/settings_icon.svg';

export const Menu = [
  { name: 'Student', icon: studentIcon, url: '/student/dashboard' },
  { name: 'Messages', icon: messageIcon, url: '/messages' },
  { name: 'Notice', icon: noticeIcon, url: '/notice' },
  { name: 'Contact', icon: contactIcon, url: '/contact' },
  { name: 'Report', icon: reportIcon, url: '/report' },
];

export const ResponsiveMenu = [
  { name: 'Home', icon: logo, url: '/main' },
  {
    name: 'Student',
    icon: studentIcon,
    url: '/student/dashboard',
    expanded: [
      { name: 'Student Profile', url: '/student/dashboard' },
      { name: 'Grade', url: '/student/grade' },
      { name: 'Period', url: '/student/term' },
      { name: 'Subject', url: '/student/subject' },
      { name: 'Category', url: '/student/category' },
      { name: 'Assign Student', url: '/student/assign' },
      { name: 'Attendance', url: '/student/attendance' },
    ],
  },
  { name: 'Messages', icon: messageIcon, url: '/messages' },
  { name: 'Notice', icon: noticeIcon, url: '/notice' },
  { name: 'Contact', icon: contactIcon, url: '/contact' },
  { name: 'Report', icon: reportIcon, url: '/report' },
  { name: 'Admin', icon: adminIcon, url: '/admin', needPrivilege: true },
  { name: 'Settings', icon: settingsIcon, url: '/settings' },
];
