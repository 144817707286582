import * as React from 'react';
import ScrollableModal from '../../ScrollableModal';
import {CustomTextField} from '../TextField';
import UploadFiled from '../UploadField';
import BasicButton from '../../BasicButton';
import UploadPhoto from '../../UploadPhoto/UploadPhoto';
import {
  validateEmail, validateLength,
  validatePassword,
  validateRequired,
} from '../../../utili/custom_validator';
import axiosWithToken from '../../../utili/axiosWithToken';
import {connect} from 'react-redux';
import {
  UpdateUserPassword,
  UpdateUserProfile,
} from '../../../store/profile/profileActions';
import key, {S3_URL} from '../../../config/keys';
import {AddSuccessAlert} from '../../../store/alert/alert';
import './ProfileEditor.scss'
import store from '../../../store/store';
import {SET_USER_AVATAR} from '../../../store/profile/profileConstants';
import {withStyles} from '@material-ui/core';

const styles = (theme) => ({
  changeSignatureText: {
    cursor: 'pointer',
    color: theme.primary.main,
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 300,
    marginBottom: 0,
    marginTop: '12px'
  }
})

class ProfileEditor extends React.Component {
  constructor(props) {
    super(props)
    this.signatureField = React.createRef()
    this.state = {
      isBusy: true,
      modalIsOpen: false,
      form: {
        avatar: {
          url: ""
        },
        firstName: {
          value: "",
          isValid: true,
          error: ""
        },
        lastName: {
          value: "",
          isValid: true,
          error: ""
        },
        email: {
          value: "",
          isValid: true,
          error: ""
        },
        signature: {
          url: ""
        },
        oldPassword: {
          value: "",
          isValid: true,
          error: ""
        },
        newPassword: {
          value: "",
          isValid: true,
          error: ""
        },
        confirmPassword: {
          value: "",
          isValid: true,
          error: ""
        },
        updated_email: ""
      },
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if(!prevState.modalIsOpen && this.state.modalIsOpen) {
      await this.getProfile()
    }
  }

  isMainFormValid = (data) => {
    const firstNameValidation = validateRequired(data.firstName.value);
    const lastNameValidation = validateRequired(data.lastName.value);
    const emailValidation = validateEmail(data.email.value);
    return new Promise((resolve) => {
      this.setState({
        form: {
          ...this.state.form,
          firstName: {
            ...this.state.form.firstName,
            isValid: firstNameValidation.isValid,
            error: firstNameValidation.error,
          },
          lastName: {
            ...this.state.form.lastName,
            isValid: lastNameValidation.isValid,
            error: lastNameValidation.error
          },
          email: {
            ...this.state.form.email,
            isValid: emailValidation.isValid,
            error: emailValidation.error
          },
        }
      }, () => {
        if(!this.state.form.firstName.isValid
            || !this.state.form.lastName.isValid
            || !this.state.form.email.isValid) {
          return resolve(false)
        }
        return resolve(true)
      })
    })
  }
  isPasswordFormValid = (data) => {
    return new Promise(resolve => {
      this.setState({
        form: {
          ...this.state.form,
          oldPassword: {
            ...this.state.form.oldPassword,
            ...validateRequired(data.oldPassword.value)
          },
          newPassword: {
            ...this.state.form.newPassword,
            ...validateRequired(data.newPassword.value)
          },
          confirmPassword: {
            ...this.state.form.confirmPassword,
            ...validateRequired(data.confirmPassword.value)
          },
        }
      }, () => {
        if(
            !this.state.form.oldPassword.isValid ||
            !this.state.form.newPassword.isValid ||
            !this.state.form.confirmPassword.isValid) {
          return resolve(false)
        }
        const passwordsMatching = validatePassword(this.state.form.newPassword.value, this.state.form.confirmPassword.value);
        if(!passwordsMatching.isValid) {
          this.setState({
            form: {
              ...this.state.form,
              newPassword: {
                ...this.state.form.newPassword,
                isValid: false,
                error: passwordsMatching.error
              },
              confirmPassword: {
                ...this.state.form.confirmPassword,
                isValid: false,
                error: passwordsMatching.error
              },
            }
          })
          return resolve(false)
        } else {
          const passwordLength = validateLength(this.state.form.newPassword.value, 6)
          if(!passwordLength.isValid) {
            this.setState({
              form: {
                ...this.state.form,
                newPassword: {
                  ...this.state.form.newPassword,
                  isValid: false,
                  error: passwordLength.error
                },
                confirmPassword: {
                  ...this.state.form.confirmPassword,
                  isValid: false,
                  error: passwordLength.error
                },
              }
            })
            return resolve(false)
          } else {
            this.setState({
              form: {
                ...this.state.form,
                newPassword: {
                  ...this.state.form.newPassword,
                  isValid: true,
                  error: ""
                },
                confirmPassword: {
                  ...this.state.form.confirmPassword,
                  isValid: true,
                  error: ""
                },
              }
            })
          }
        }
        return resolve(true)
      })
    })
  }

  resetMainForm = () => {
    this.setState({
      isBusy: true,
      form: {
        ...this.state.form,
        firstName: {
          value: "",
          isValid: true,
          error: ""
        },
        lastName: {
          value: "",
          isValid: true,
          error: ""
        },
        email: {
          value: "",
          isValid: true,
          error: ""
        },
        oldPassword: {
          value: "",
          isValid: true,
          error: ""
        },
        newPassword: {
          value: "",
          isValid: true,
          error: ""
        },
        confirmPassword: {
          value: "",
          isValid: true,
          error: ""
        }
      }
    })
  }

  resetPasswordForm = () => {
    this.setState({
      form: {
        ...this.state.form,
        oldPassword: {
          value: "",
          isValid: true,
          error: ""
        },
        newPassword: {
          value: "",
          isValid: true,
          error: ""
        },
        confirmPassword: {
          value: "",
          isValid: true,
          error: ""
        },
      }
    })
  }

  updateProfile = async () => {
    try {
      const shouldValidatePassword = this.state.form.oldPassword.value
          || this.state.form.newPassword.value
          || this.state.form.confirmPassword.value
      const sendData = {}
      if(!(await this.isMainFormValid(this.state.form))) {
        return;
      }
      this.setState({isBusy: true})
      sendData.first_name = this.state.form.firstName.value.trim()
      sendData.last_name = this.state.form.lastName.value.trim()
      sendData.email = this.state.form.email.value.trim()
      sendData.avatar = this.state.form.avatar
      sendData.signature = this.state.form.signature
      await this.props.UpdateUserProfile(sendData).then(async () => {
        await this.getProfile()
        sessionStorage.setItem('avatar', this.state.form.avatar.url);
        store.dispatch({ type: SET_USER_AVATAR, payload: this.state.form.avatar.url})
        !shouldValidatePassword && this._hide()
      })

      if(shouldValidatePassword) {
        if(!(await this.isPasswordFormValid(this.state.form))) {
          this.setState({isBusy: false})
          return;
        }
        const sendData = {}
        sendData.OldPass = this.state.form.oldPassword.value
        sendData.NewPass = this.state.form.newPassword.value
        await this.props.UpdateUserPassword(sendData).then(() => this._hide())
      }
      this.resetPasswordForm()
      this.props.AddSuccessAlert('Profile updated successfully!')
    } finally {
      this.setState({isBusy: false})
    }
  }


  getProfile = async () => {
    const res = await axiosWithToken.get(`/me/profile`);
    this.setState({
      form: {
        ...this.state.form,
        firstName: {
          ...this.state.form.firstName,
          value: res.data.first_name
        },
        lastName: {
          ...this.state.form.lastName,
          value: res.data.last_name
        },
        email: {
          ...this.state.form.email,
          value: res.data.email
        },
        signature: res.data.signature ? {url: `${key.S3_URL}/${res.data.signature}`} : {url: ""},
        avatar: res.data.avatar ? {url: res.data.avatar} : {url: ""},
        updated_email: res.data.updated_email
      },
      isBusy: false
    })
  }

  changeValue = (e) => {
    const {name, value} = e.target
    this.setState({
      form: {
        ...this.state.form,
        [name]: {
          ...this.state.form[name],
          value: name == 'lastName' || name == 'firstName' ? value.replace(/\d/g, '') :value,
          error: "",
          isValid: true
        }
      }
    })
  }

  _hide = () => {
    this.setState({
      modalIsOpen: false
    })
  }

  _show = () => {
    this.setState({
      modalIsOpen: true
    })
  }


  render() {
    const {modalIsOpen, isBusy} = this.state
    return (
          <div id="scrollableModal">
            <ScrollableModal
                onClose={() => {
                  this._hide()
                  this.resetMainForm()
                }}
                modalIsOpen={modalIsOpen}
                containerClass="profile-editor-wrapper"
            >
              <div id="profile-editor-content">
                <div className="d-flex justify-content-center">
                  <UploadPhoto
                      photo={this.state.form.avatar}
                      setPhoto={(file) => {this.setState({
                        form: {
                          ...this.state.form,
                          avatar: file
                        }
                      })}}
                  />
                </div>

                <div className="content-wrapper pb-0">
                  <div className="d-flex justify-content-center mt-3 section-title">
                    <p>Update User Profile</p>
                  </div>
                  <div className="row form-group w-100">
                    <label htmlFor="firstName" className="label-for-input">
                      First Name
                    </label>
                    <div className="w-100 mb-4">
                      <CustomTextField
                          value={this.state.form.firstName.value}
                          placeholder="Type here"
                          name="firstName"
                          type="text"
                          className="form-control text-capitalize"
                          variant="outlined"
                          size="small"
                          onChange={this.changeValue}
                          error={!this.state.form.firstName.isValid}
                      />
                      <small className="text-danger">{this.state.form.firstName.error}</small>
                    </div>
                  </div>

                  <div className="row form-group w-100">
                    <label htmlFor="lastName" className="label-for-input">
                      Last Name
                    </label>
                    <div className="w-100 mb-4">
                      <CustomTextField
                          value={this.state.form.lastName.value}
                          placeholder="Type here"
                          name="lastName"
                          type="text"
                          className="form-control text-capitalize"
                          variant="outlined"
                          size="small"
                          onChange={this.changeValue}
                          error={!this.state.form.lastName.isValid}
                      />
                      <small className="text-danger">{this.state.form.lastName.error}</small>
                    </div>
                  </div>

                  <div className="row form-group">
                    <label htmlFor="email" className="label-for-input">
                      Email Address
                    </label>
                    <div className="w-100">
                      <CustomTextField
                          value={this.state.form.email.value}
                          placeholder="Type here"
                          name="email"
                          type="text"
                          className="form-control text-capitalize"
                          variant="outlined"
                          size="small"
                          onChange={this.changeValue}
                          error={!this.state.form.email.isValid}
                      />
                      <small className="text-danger">{this.state.form.email.error}</small>
                      {!!this.state.form.updated_email
                      && <small className="email-update-message">{`Confirmation link has been sent to ${this.state.form.updated_email}. Email will be changed after new email is verified.`}</small>}
                    </div>
                  </div>

                  <div className="row form-group d-flex">
                    <UploadFiled
                        ref={this.signatureField}
                        text="Upload Signature"
                        wrapperClassName="w-100 signature-upload"
                        imageWrapperClassName={`signature-image-wrapper`}
                        showImageBorder={true}
                        onFileUpload={file => this.setState({form: {
                            ...this.state.form,
                            signature: file
                          }}
                        )}
                        src={this.state.form.signature.url}
                    />
                  </div>
                  {
                    (this.state.form.signature.url
                    || this.state.form.signature.name)
                    && <p className={this.props.classes.changeSignatureText} onClick={() => this.signatureField.current.triggerInput()}>Change signature</p>
                  }
                </div>

                <div className="separator"></div>

                <div className="content-wrapper">
                  <div className="d-flex justify-content-center mt-3 section-title">
                    <p>Update your password</p>
                  </div>

                  <div className="row form-group">
                    <label htmlFor="oldPassword" className="label-for-input">
                      Old Password
                    </label>
                    <div className="w-100 mb-4">
                      <CustomTextField
                          type="password"
                          value={this.state.form.oldPassword.value}
                          placeholder="Type here"
                          name="oldPassword"
                          className="form-control text-capitalize"
                          variant="outlined"
                          size="small"
                          onChange={this.changeValue}
                          error={!this.state.form.oldPassword.isValid}
                      />
                      <small className="text-danger">{this.state.form.oldPassword.error}</small>
                    </div>
                  </div>

                  <div className="row form-group">
                    <label htmlFor="newPassword" className="label-for-input">
                      New Password
                    </label>
                    <div className="w-100 mb-4">
                      <CustomTextField
                          type="password"
                          value={this.state.form.newPassword.value}
                          placeholder="Type here"
                          name="newPassword"
                          className="form-control text-capitalize"
                          variant="outlined"
                          size="small"
                          onChange={this.changeValue}
                          error={!this.state.form.newPassword.isValid}
                      />
                      <small className="text-danger">{this.state.form.newPassword.error}</small>
                    </div>
                  </div>

                  <div className="row form-group">
                    <label htmlFor="confirmPassword" className="label-for-input">
                      Confirm Password
                    </label>
                    <div className="w-100 mb-4">
                      <CustomTextField
                          type="password"
                          value={this.state.form.confirmPassword.value}
                          placeholder="Type here"
                          name="confirmPassword"
                          className="form-control text-capitalize"
                          variant="outlined"
                          size="small"
                          onChange={this.changeValue}
                          error={!this.state.form.confirmPassword.isValid}
                      />
                      <small className="text-danger">{this.state.form.confirmPassword.error}</small>
                    </div>
                  </div>

                  <div className="d-flex justify-content-end">
                    <button className="btn-text p-2 mr-3" onClick={() => {
                      this._hide()
                      this.resetMainForm()
                    }}>Cancel</button>
                    <BasicButton text="Update" disabled={isBusy} onClick={this.updateProfile} />
                  </div>
                </div>

              </div>
            </ScrollableModal>
          </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    currentUserId: state.auth._id,
  }
}

export default withStyles(styles)(connect(mapStateToProps, {UpdateUserProfile, UpdateUserPassword, AddSuccessAlert}, null, {forwardRef: true})(ProfileEditor));
