import {
  GET_BASIC_INFO,
  UPDATE_BASIC_INFO,
  UPDATE_BANNER,
  UPDATE_CREST,
  UPDATE_SIGNATURE,
  SET_USERS,
  SET_USER_AVATAR,
} from './profileConstants';

const initState = {
  currentUser: {
    school_name: '',
    Banner: '',
    Signature: '',
    Crest: '',
    Address: '',
    Phone: '',
    SchoolUsers: [],
    Avatar: ""
  },
};

export default (state = initState, action) => {
  switch (action.type) {
    case GET_BASIC_INFO:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          school_name: action.payload.name,
          Phone: action.payload.phone,
          Address: action.payload.address,
          Banner: action.payload.banner,
          Signature: action.payload.signature,
          Crest: action.payload.crest,
        },
      };

    case UPDATE_BASIC_INFO:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          school_name: action.payload.SchoolName,
          Address: action.payload.Address,
          Phone: action.payload.Telephone,
        },
      };
    case UPDATE_BANNER:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          Banner: action.payload,
        },
      };
    case UPDATE_CREST:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          Crest: action.payload,
        },
      };
    case UPDATE_SIGNATURE:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          Signature: action.payload,
        },
      };
    case SET_USERS: {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          SchoolUsers: action.payload,
        }
      }
    }
    case SET_USER_AVATAR: {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          Avatar: action.payload,
        }
      }
    }
    default:
      return state;
  }
};
