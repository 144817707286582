import axios from 'axios';
import {
  GET_BASIC_INFO,
  UPDATE_BASIC_INFO,
  UPDATE_BANNER,
  UPDATE_CREST,
  UPDATE_SIGNATURE, SET_USERS,
} from './profileConstants';

import { AddErrorAlert, AddSuccessAlert } from '../alert/alert';
import axiosWithToken from '../../utili/axiosWithToken';
import {CHANGE_THEME} from '../ui/uiConstants';
export const GetBasicInfo = () => async (dispatch) => {
  try {
    const res = await axiosWithToken.get(`/profile`);
    dispatch({
      type: GET_BASIC_INFO,
      payload: res.data,
    });
  } catch (error) {
    dispatch(AddErrorAlert(error));
  }
};

export const UpdateBasicInfo = (submitData) => async (dispatch) => {
  try {
    //{SchoolName,Address,Telephone}
    const result = await axiosWithToken.post('/schoolinfo', submitData);
    console.log(result.data);

    dispatch({
      type: UPDATE_BASIC_INFO,
      payload: submitData,
    });

    dispatch(AddSuccessAlert('School Info has been updated successfully.'));
  } catch (error) {
    console.log(error);
    dispatch(AddErrorAlert(error));
  }
};

export const UpdateBanner = (NewFile, fnCallBack) => async (dispatch) => {
  try {
    if (!NewFile) return;

    var newUrl = await UpdateProfileImages(NewFile, '/banner', fnCallBack);

    dispatch({
      type: UPDATE_BANNER,
      payload: newUrl,
    });

    dispatch(AddSuccessAlert('School banner has been updated successfully.'));
  } catch (error) {
    dispatch(AddErrorAlert(error));
  }
};

export const UpdateCrest = (NewFile, fnCallBack) => async (dispatch) => {
  try {
    if (!NewFile) return;

    var newUrl = await UpdateProfileImages(NewFile, '/crest', fnCallBack);

    dispatch({
      type: UPDATE_CREST,
      payload: newUrl,
    });

    dispatch(AddSuccessAlert('School crest has been updated successfully.'));
  } catch (error) {
    dispatch(AddErrorAlert(error));
  }
};

export const UpdatePrincipalSignature = (NewFile, fnCallBack) => async (
  dispatch
) => {
  try {
    if (!NewFile) return;

    var newUrl = await UpdateProfileImages(NewFile, '/signature', fnCallBack);

    dispatch({
      type: UPDATE_SIGNATURE,
      payload: newUrl,
    });
    dispatch(
      AddSuccessAlert('School signature has been updated successfully.')
    );
  } catch (error) {
    dispatch(AddErrorAlert(error));
  }
};

const UpdateProfileImages = async (NewFile, postUrl, fnCallBack) => {
  let uploadConfig = null;
  uploadConfig = await axiosWithToken.get('/api/upload/profile', {
    params: {
      ContentType: NewFile.type,
    },
  });

  await axiosWithToken.post(postUrl, {
    filePath: uploadConfig.data.key,
  });

  if (uploadConfig) {
    //now upload the image
    await axios.put(uploadConfig.data.url, NewFile, {
      headers: {
        'Content-Type': NewFile.type,
      },
    });
  }

  if (fnCallBack && typeof fnCallBack === 'function') fnCallBack();

  //return new file url
  return uploadConfig.data.key;
};

export const UpdateTheme = (theme) => async (dispatch) => {
  try {
    const result = await axiosWithToken.post('/theme', {ThemeName: theme._name});
    console.log(result.data);

    sessionStorage.setItem('theme', theme._name);
    dispatch({
      type: CHANGE_THEME,
      payload: theme,
    });

    dispatch(AddSuccessAlert('Theme has been updated successfully.'));
  } catch (error) {
    dispatch(AddErrorAlert(error));
  }
};

export const GetUsers = (searchString) => async (dispatch) => {
  try {
    let path = '/school/users'
    if(searchString) {
      path = path + `?searchString=${searchString}`
    }
    const result = await axiosWithToken.get(path)

    if(result) {
      dispatch({
        type: SET_USERS,
        payload: result.data,
      });
    }
  } catch (error) {
    dispatch(AddErrorAlert(error));
  }
};

export const AddUser = (data) => async (dispatch) => {
  try {
    let uploadConfig = null
    const {Signature, ...submitData} = data
    if (Signature) {
      uploadConfig = await axiosWithToken.get('/api/upload/signatures', {
        params: {
          ContentType: data.Signature.type,
        },
      });

      if (uploadConfig) {
        //now upload the image
        await axios.put(uploadConfig.data.url, Signature, {
          headers: {
            'Content-Type': Signature.type,
          },
        });
      }
    }
    submitData.Signature = uploadConfig ? uploadConfig.data.key : ''

    const result = await axiosWithToken.post('/school/user', submitData).then(() => {
      console.log(result);
    })

    return await Promise.resolve()
  } catch (error) {
    return await Promise.reject(error)
  }
};

export const UpdateUserProfile = (data) => async (dispatch) => {
  const {avatar, signature, ...sendData} = data
  try {
    let avatarUploadConfig = null
    let signatureUploadConfig = null
    if (signature && signature.name) {
      signatureUploadConfig = await axiosWithToken.get('/api/upload/signatures', {
        params: {
          ContentType: signature.type,
        },
      });

      if (signatureUploadConfig) {
        //now upload the image
        await axios.put(signatureUploadConfig.data.url, signature, {
          headers: {
            'Content-Type': signature.type,
          },
        });
        sendData.signature = signatureUploadConfig ? signatureUploadConfig.data.key : null
      } else {
        if(signature.url === "") sendData.signature = null
      }
    }
    if (avatar && avatar.filename) {
//      avatarUploadConfig = await axiosWithToken.get('/api/upload/avatars', {
//        params: {
//          ContentType: avatar.type,
//        },
//      });
//
//      if (avatarUploadConfig) {
//        //now upload the image
//        await axios.put(avatarUploadConfig.data.url, avatar, {
//          headers: {
//            'Content-Type': avatar.type,
//          },
//        });
//        sendData.avatar = avatarUploadConfig ? avatarUploadConfig.data.key : null
//      }
      sendData.avatar = avatar.url
    } else {
      if(avatar.url === "") sendData.avatar = null
    }
    const result = await axiosWithToken.post('/me/profile', sendData).then(() => {
      console.log(result);
    })
    return await Promise.resolve()
  } catch (error) {
    dispatch(AddErrorAlert(error));
    return await Promise.reject()
  }
};

export const UpdateUserPassword = (data) => async (dispatch) => {
  try {
    await axiosWithToken.post('/updatepassword', data)
    return await Promise.resolve()
  } catch (error) {
    dispatch(AddErrorAlert(error));
    return await Promise.reject()
  }
};

//export const GetUserProfile = () => async (dispatch) => {
//  try {
//    const res = await axiosWithToken.get(`/me/profile`);
//    dispatch({
//      type: SET_USER_PROFILE,
//      payload: res.data,
//    });
//  } catch (error) {
//    dispatch(AddErrorAlert(error));
//  }
//};

export const RemoveUser = (user) => async (dispatch) => {
  try {
    if(!user) {
      dispatch(AddErrorAlert('Something went wrong: no user provided'));
    }
    await axiosWithToken.delete(`/school/user/${user._id}`)
    dispatch(AddSuccessAlert(`User ${user.first_name|| ''} ${user.last_name || ''} has been removed`));
    return await Promise.resolve()
  } catch (error) {
    dispatch(AddErrorAlert(error));
    return await Promise.reject()
  }
};

export const UpdatePermissions = ({userId, permissions}) => async (dispatch) => {
  try {
    await axiosWithToken.post(`/school/user/${userId}/permissions`, {permissions})
    dispatch(AddSuccessAlert(`Permissions successfully updated`));
    return await Promise.resolve()
  } catch (error) {
    dispatch(AddErrorAlert(error));
    return await Promise.reject()
  }
};
