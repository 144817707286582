import axios from 'axios';
import axiosWithToken from '../../utili/axiosWithToken';
import { AddErrorAlert, AddSuccessAlert } from '../alert/alert';

const GET_STUDENTS = 'GET_STUDENTS';
const DELETE_STUDENT = 'DELETE_STUDENT';
const SELECT_STUDENT_MAIN = 'SELECT_STUDENT_MAIN';
const SELECT_STUDENT_RIGHT_PANEL = 'SELECT_STUDENT_RIGHT_PANEL';
const STUDENT_SEARCHSTRING_CHANGE = 'STUDENT_SEARCHSTRING_CHANGE';
const SET_STUDENT_TABLE_SATE = 'SET_STUDENT_TABLE_SATE'

const initialState = {
  StudentList: [],
  TotalStudents: 0,
  activeStudent: null,
  SearchString: '',
  CurrentPage: 1,
  DisplayPerPage: 5,
  SortBy: 'first_name',
  SortOrder: 'none'
};

//reducer
export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_STUDENTS:
      return {
        ...state,
        activeStudent: {
          mainSelected: null,
          selectFromRightPanel: null,
        },
        CurrentPage: payload.pageNumber,
        DisplayPerPage: payload.DisplayPerPage,
        StudentList: payload.rows,
        TotalStudents: payload.count,
      };
    case SELECT_STUDENT_MAIN:
      return {
        ...state,
        activeStudent: { ...state.activeStudent, mainSelected: payload },
      };
    case SELECT_STUDENT_RIGHT_PANEL:
      return {
        ...state,
        activeStudent: {
          ...state.activeStudent,
          selectFromRightPanel: payload,
        },
      };
    case DELETE_STUDENT:
      let updatedStudentsList = state.StudentList.filter((stud) => {
        return !payload.some((item) => stud._id === item._id);
      });

      return {
        ...state,
        activeStudent: null,
        StudentList: updatedStudentsList,
      };
    case STUDENT_SEARCHSTRING_CHANGE:
      return { ...state, SearchString: payload };
    case SET_STUDENT_TABLE_SATE: {
      return {
        ...state,
        CurrentPage: payload.pageNumber,
        DisplayPerPage: payload.DisplayPerPage,
        SortBy: payload.SortBy,
        SortOrder: payload.SortOrder
      };
    }
    default:
      return state;
  }
}

//actions
export const getStudents = (pageNumber, rowPerPage) => async (dispatch, getState) => {
  try {
    if (!pageNumber) pageNumber = 1;
    const { DisplayPerPage, SearchString, SortBy, SortOrder } = getState().student;

    const students = await axiosWithToken.get(
        `/students?pagenumber=${pageNumber}&&displayperpage=${rowPerPage || DisplayPerPage}&&searchString=${SearchString}&&sortBy=${SortBy}&&sortOrder=${SortOrder}`
    );

    dispatch({
      type: GET_STUDENTS,
      payload: { ...students.data, pageNumber,  DisplayPerPage: rowPerPage || DisplayPerPage},
    });
  } catch (error) {
    dispatch(AddErrorAlert(error));
  }
};

export const selectStudent = (student) => {
  return {
    type: SELECT_STUDENT_MAIN,
    payload: student,
  };
};
export const selectStudentRightPanel = (student) => {
  return {
    type: SELECT_STUDENT_RIGHT_PANEL,
    payload: student,
  };
};
export const setStudentTableState = (state) => {
  return {
    type: SET_STUDENT_TABLE_SATE,
    payload: state,
  };
};
export const SetStudent = (data) => async (dispatch) => {
  try {
    let newAvatar = null;
    const isNew = !data.Id;
    if (data.HeadShot && data.HeadShot.originalFile && data.HeadShot.originalFile.type) {
      newAvatar = data.HeadShot.url
    }

    let postUrl = '/students';
    if (!isNew) postUrl += `/${data.Id}`;

    await axiosWithToken.post(postUrl, {
      firstName: data.FirstName.trim(),
      lastName: data.LastName.trim(),
      initial: data.Initial.trim(),
      gender: data.Gender,
      DOB: data.DOB,
      headshot: newAvatar
        ? newAvatar
        : data.HeadShot.url
        ? data.HeadShot.url
        : '',
      teacher_id: data.Teacher_Id,
      grade_id: data.Grade_Id,
      contact: data.Contact.trim(),
      contact_email: data.ContactEmail.trim(),
      contact_phone: data.phone.trim(),
    });
    dispatch(
      AddSuccessAlert(
        `${data.FirstName} ${data.LastName} has been ${
          isNew ? 'created' : 'updated'
        }.`
      )
    );
    //dispatch(getStudents(1));

    return true;
  } catch (error) {
    dispatch(AddErrorAlert(error));
    return false;
  }
};

export const CreateStudent = (data) => async (dispatch) => {
  try {
    let uploadConfig = null;
    const isNew = !data.Id;
    if (data.HeadShotFile)
      uploadConfig = await axiosWithToken.get('/api/upload', {
        params: {
          ContentType: data.HeadShotFile.type,
        },
      });

    let postUrl = '/students';
    if (!isNew) postUrl += `/${data.Id}`;

    await axiosWithToken.post(postUrl, {
      firstName: data.FirstName.trim(),
      lastName: data.LastName.trim(),
      initial: data.Initial.trim(),
      gender: data.Gender,
      DOB: data.DOB,
      headshot: uploadConfig
          ? uploadConfig.data.key
          : data.HeadShotUrl
              ? data.HeadShotUrl
              : '',
      teacher_id: data.Teacher_Id,
      grade_id: data.Grade_Id,
      contact: data.Contact,
      contact_email: data.ContactEmail,
      phone: data.phone.trim(),
      contact_phone: data.phone.trim()
    });

    if (uploadConfig) {
      //now upload the image
      await axios.put(uploadConfig.data.url, data.HeadShotFile, {
        headers: {
          'Content-Type': data.HeadShotFile.type,
        },
      });
    }
    dispatch(
        AddSuccessAlert(
            `${data.FirstName} ${data.LastName} has been ${
                isNew ? 'created' : 'updated'
            }.`
        )
    );
    dispatch(getStudents(1));

    return true;
  } catch (error) {
    dispatch(AddErrorAlert(error));
    return false;
  }
};

export const DeleteStudent = () => async (dispatch, getState) => {
  const { activeStudent, DisplayPerPage } = getState().student;
  const currentPage = getState().student.CurrentPage
  try {
    let StudentForDeletion;

    if (activeStudent.selectFromRightPanel) {
      StudentForDeletion = activeStudent.selectFromRightPanel;
    } else {
      StudentForDeletion = activeStudent.mainSelected;
    }

    const promises = [];
    for (let item of StudentForDeletion) {
      const request = axiosWithToken.delete(`/students/${item._id}`);
      promises.push(request);
    }
    await Promise.all(promises);
    // dispatch({
    //   type: DELETE_STUDENT,
    //   payload: StudentForDeletion,
    // });
    if (StudentForDeletion.lenght > 1) {
      dispatch(AddSuccessAlert(`Chosen students have been removed.`));
      if (StudentForDeletion.lenght === DisplayPerPage && currentPage !== 1) {
        dispatch(getStudents(currentPage - 1, DisplayPerPage));
      } else {
        dispatch(getStudents(currentPage, DisplayPerPage));
      }
    } else {
      console.log('}}}', StudentForDeletion)
      dispatch(
        AddSuccessAlert(
          `${StudentForDeletion[0].first_name} ${StudentForDeletion[0].last_name} has been removed.`
        )
      );
      dispatch(getStudents(currentPage, DisplayPerPage));
    }
  } catch (error) {
    console.log('>>>--', error)
    dispatch(AddErrorAlert(error));
    dispatch(getStudents(currentPage, DisplayPerPage));
  }
};

export const SendStudentID = () => async (dispatch, getState) => {
  try {
    const { activeStudent } = getState().student;
    let StudentForSendingID;

    if (activeStudent.selectFromRightPanel) {
      StudentForSendingID = activeStudent.selectFromRightPanel;
    } else {
      StudentForSendingID = activeStudent.mainSelected;
    }

    await axiosWithToken.post(`/students/${StudentForSendingID[0]._id}/sendid`);

    dispatch(AddSuccessAlert('The email has been sent.'));
  } catch (error) {
    dispatch(AddErrorAlert(error));
  }
};

export const StudentSearchStringChange = (searchString) => {
  return {
    type: STUDENT_SEARCHSTRING_CHANGE,
    payload: searchString,
  };
};
