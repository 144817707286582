import shortid from 'shortid';

const ADD_ERROR_ALERT = 'ADD_ERROR_ALERT';
const ADD_SUCCESS_ALERT = 'ADD_SUCCESS_ALERT';
const REMOVE_ALERT = 'REMOVE_ALERT';
const initialState = {
  alertList: [],
};
//reducer
export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case ADD_ERROR_ALERT:
      return {
        alertList: [
          ...state.alertList,
          { level: 'danger', message: payload, id: shortid.generate() },
        ],
      };
    case ADD_SUCCESS_ALERT:
      return {
        alertList: [
          ...state.alertList,
          { level: 'success', message: payload, id: shortid.generate() },
        ],
      };
    case REMOVE_ALERT:
      return { alertList: state.alertList.filter((msg) => msg.id !== payload) };
    default:
      return state;
  }
}

//actions
export const AddErrorAlert = (error) => {
  let message = '';

  if (typeof error === 'string') {
    message = error;
  } else {
    const isClientError = !error.response;

    if (isClientError) {
      message =
        (!isClientError && error.response.data.error) ||
        'An unexpected has occured.';
      //log error
    }
  }

  if (!message)
    return {
      type: null,
      payload: message,
    };

  return {
    type: ADD_ERROR_ALERT,
    payload: message,
  };
};

export const AddSuccessAlert = (message) => {
  return {
    type: ADD_SUCCESS_ALERT,
    payload: message,
  };
};
export const RemoveAlert = (msgId) => {
  return {
    type: REMOVE_ALERT,
    payload: msgId,
  };
};
