import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
export default function (ComposedComponent) {
  class AuthenticationAdmin extends Component {
    componentWillMount() {
      if (!this.props.IsAdmin) {
        this.props.history.push('/home');
      }
    }
    componentWillUpdate(nextProps) {
      if (!nextProps.IsAdmin) {
        this.props.history.push('/');
      }
    }
    render() {
      return <ComposedComponent {...this.props} />;
    }
  }
  function mapStateToProps(state) {
    return {
      IsAdmin: state.auth.IsAdmin === 'true' && state.auth.authenticated,
    };
  }
  return withRouter(connect(mapStateToProps)(AuthenticationAdmin));
}
