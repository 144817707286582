import React, { Component } from 'react';
import { connect } from 'react-redux';

//assets
import logoIcon from '../../assets/images/logo.svg';
import BGSignIn from '../../assets/images/BG/BG_Sign_in.jpg';

//store
import { signinUser } from '../../store/auth/authActions';
import { AddErrorAlert } from '../../store/alert/alert';

//components
import MaterialInput from '../../components/Forms/MaterialInput/MaterialInput';
import Checkbox from '../../components/Forms/Checkbox/Checkbox';
import Alert from '../../components/Alert/Alert';

//style
import './Login.scss';

//page specific component
import ResetPassword from './ResetPassword';

export class Login extends Component {
  state = {
    isSigningIn: false,
    email: '',
    password: '',
    remember_me: false,
  };

  componentDidMount() {
    const username = localStorage.getItem('username');
    if (username) {
      this.setState({ email: username, remember_me: true });
    }
    const query = new URLSearchParams(this.props.location.search);
    if (query.get("reset")) {
      this.props.history.push(`/reset/${query.get("reset")}`);
    }
  }

  handleChange = (event) => {
    this.setState({ [event.currentTarget.name]: event.target.value });
  };

  toggleRememberMe = (evt) => {
    this.setState((prevState) => ({ remember_me: !prevState.remember_me }));
  };

  onSubmit = async (evt) => {
    evt.preventDefault();
    const { email, password, remember_me } = this.state;
    const { dispatch, AddErrorAlert, signinUser } = this.props;

    if (!email) {
      return dispatch(AddErrorAlert('Please enter your username.'));
    }
    if (!password) {
      return dispatch(AddErrorAlert('Please enter your password.'));
    }

    this.setState({ isSigningIn: true });

    await dispatch(signinUser(email, password));
    this.setState({ isSigningIn: false });

    //set up remember me
    if (remember_me) localStorage.setItem('username', email);
    else localStorage.removeItem('username');
  };

  render() {
    const today = new Date();
    return (
      <div className=" Login-page">
        <Alert />
        <div className="PanelLogin">
          <div className="login-card">
            <img src={logoIcon} className="img img-fluid Logo" alt="e-TELL" />

            <h4 className="welcome">Welcome to e-TELL!</h4>

            <p className="signInMsg text-graybitdarker">
              Please sign in below
            </p>
            <form onSubmit={this.onSubmit}>
              <MaterialInput
                  id="txtUsername"
                  className="mb-2"
                  type="text"
                  label="Email"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                  autoComplete="new-email"
              />
              <MaterialInput
                  id="txtPassword"
                  type="password"
                  label="Password"
                  value={this.state.password}
                  name="password"
                  onChange={this.handleChange}
                  autoComplete="new-password"
              />

              <div className="d-flex justify-content-between w-100 py-3">
                <Checkbox
                    label="Remember Me"
                    onChange={this.toggleRememberMe}
                    checked={this.state.remember_me}
                />

                <ResetPassword />
              </div>

              <div className="w-100 text-center mt-2">
                <button
                    type="submit"
                    disabled={this.state.isSigningIn ? true : null}
                    className="btn btn-info btn-enter"
                >
                  {this.state.isSigningIn ? 'Signing In...' : 'Enter'}
                </button>
              </div>
            </form>
          </div>
          <div className="LoginCopyRight">
            <small>
              Copyright &copy; {today.getFullYear()}. All Rights Reserved.
              e-Tell
            </small>
          </div>
        </div>
        <div className="PanelImage">
          <img src={BGSignIn} alt="Login" />
        </div>
      </div>
    );
  }
}

const actions = (dispatch) => ({
  dispatch,
  signinUser,
  AddErrorAlert,
});

export default connect(null, actions)(Login);
