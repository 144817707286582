import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export function EmptyAvatarIcon({fillColor = '#4A4A4B', ...props}) {
  return (
      <SvgIcon viewBox="0 0 126.16 126.16" {...props}>
          <g id="Empty-User-Placeholder" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(0, 0)">
              <path d="M23.51,106.47 C46.1521342,128.164458 81.8678658,128.164458 104.51,106.47 C80.6127805,88.1642551 47.4072195,88.1642551 23.51,106.47 L23.51,106.47 Z" id="Path"></path>
              <path d="M64,5.26999328 C41.8039416,5.25940786 21.5008327,17.7714472 11.5330033,37.6034288 C1.56517381,57.4354105 3.63816626,81.1939892 16.89,99 C44.5694204,77.3611107 83.4305796,77.3611107 111.11,99 C124.361834,81.1939892 126.434826,57.4354105 116.466997,37.6034288 C106.499167,17.7714472 86.1960584,5.25940786 64,5.26999328 Z M64,75.07 C48.1328591,75.07 35.27,62.2071409 35.27,46.34 C35.27,30.4728591 48.1328591,17.61 64,17.61 C79.8671409,17.61 92.73,30.4728591 92.73,46.34 C92.7134645,62.2002866 79.8602866,75.0534645 64,75.07 Z" id="Shape"></path>
              <path d="M64,27 C53.3243358,27 44.67,35.6543358 44.67,46.33 C44.67,57.0056642 53.3243358,65.66 64,65.66 C74.6756642,65.66 83.33,57.0056642 83.33,46.33 C83.3189761,35.6589054 74.6710946,27.0110239 64,27 L64,27 Z" id="Path"></path>
              <path d="M64,17.61 C48.1328591,17.61 35.27,30.4728591 35.27,46.34 C35.27,62.2071409 48.1328591,75.07 64,75.07 C79.8671409,75.07 92.73,62.2071409 92.73,46.34 C92.7134645,30.4797134 79.8602866,17.6265355 64,17.61 Z M64,65.67 C53.3243358,65.67 44.67,57.0156642 44.67,46.34 C44.67,35.6643358 53.3243358,27.01 64,27.01 C74.6756642,27.01 83.33,35.6643358 83.33,46.34 C83.3189761,57.0110946 74.6710946,65.6589761 64,65.67 L64,65.67 Z" id="Shape" fill={fillColor} fillRule="nonzero"></path>
              <path d="M64,0.92 C29.161878,0.92 0.92,29.161878 0.92,64 C0.92,98.838122 29.161878,127.08 64,127.08 C98.838122,127.08 127.08,98.838122 127.08,64 C127.041422,29.17787 98.82213,0.958578007 64,0.92 Z M64,122.730021 C48.9110595,122.736558 34.4035541,116.910625 23.51,106.47 C47.4072195,88.1642551 80.6127805,88.1642551 104.51,106.47 C93.612028,116.916304 79.0960557,122.742759 64,122.730021 L64,122.730021 Z M64,82.79 C46.9312845,82.8059663 30.3546514,88.5097919 16.89,99 C-1.16294438,74.7488036 2.14445941,40.7544702 24.5335178,20.4379894 C46.9225761,0.121508542 81.0774239,0.121508542 103.466482,20.4379894 C125.855541,40.7544702 129.162944,74.7488036 111.11,99 C97.6468232,88.5070711 81.0692272,82.8029142 64,82.79 Z" id="Shape" fill={fillColor} fillRule="nonzero" opacity="0.4"></path>
            </g>
          </g>
      </SvgIcon>
  );
}
