import axios from 'axios';
import store from '../store/store';
import { AddErrorAlert } from '../store/alert/alert';
import { ROOT_URL } from '../config/keys';

axios.interceptors.response.use(null, function (error) {
  if (error && error.response && error.response.data && error.response.data.error) {
    //alert(error.response.data.error);
    store.dispatch(AddErrorAlert(error.response.data.error));
  }
  return Promise.reject(error);
});

const axiosWithToken = (() => {
  const defaultOptions = {
    headers: {
      authorization: sessionStorage.getItem('token'),
      schoolid: sessionStorage.getItem('schoolid'),
    },
  };

  return {
    get: (url, options = {}) =>
      axios.get(`${ROOT_URL}${url}`, { ...defaultOptions, ...options }),
    post: (url, data, options = {}) =>
      axios.post(`${ROOT_URL}${url}`, data, { ...defaultOptions, ...options }),
    put: (url, data, options = {}) =>
      axios.put(`${ROOT_URL}${url}`, data, { ...defaultOptions, ...options }),
    delete: (url, options = {}) =>
      axios.delete(`${ROOT_URL}${url}`, { ...defaultOptions, ...options }),
  };
})();
//usage
//const request = axiosWithToken('MY SECRET TOKEN');
//request.get(PAGES_URL);

export default axiosWithToken;
