const ThemeColors = {
  colorETellGreenDefault: '#5f7a16',
  colorDarkBlueThemeSelect: '#123273',
  colorMintThemeSelect: '#72a0a0',
  colorGreenThemeSelect: '#365953',
  colorOliveThemeSelect: '#8ba68a',
  colorDarkRedThemeSelect: '#732210',
  colorMuddyCherryThemeSelect: '#733636',
  colorPurpleThemeSelect: '#754896',
  colorDarkPinkThemeSelect: '#bf6374',
  colorPeachThemeSelect: '#ec7964',
  colorYellowThemeSelect: '#be9900',
};
export default ThemeColors;
