export const EDIT_STUDENT = "EDIT_STUDENT";
export const DELETE_STUDENT = "DELETE_STUDENT";
export const SEND_ID = "SEND_ID";
export const MULTI_DELETE_STUDENT = "MULTI_DELETE_STUDENT";

export const EDIT_PERIOD = "EDIT_PERIOD";
export const DELETE_PERIOD = "DELETE_PERIOD";
export const MULTI_DELETE_PERIOD = "MULTI_DELETE_PERIOD";

export const RESPONSIVE = "RESPONSIVE";

/*TABLES HEAD CELLS*/
export const STUDENT_HEAD_CELLS = [
  {
    id: "headshot",
    align: "center",
    label: "",
    classTitle: "cell-avatar",
    dataIndex: "headshot",
    key: "headshot",
  },
  {
    id: "firstName",
    align: "left",
    label: "First Name",
    classTitle: "cell-auto",
    dataIndex: "first_name",
    key: "first_name",
  },
  {
    id: "lastName",
    align: "left",
    label: "Last Name",
    classTitle: "cell-auto",
    dataIndex: "last_name",
    key: "last_name",
  },
  {
    id: "initial",
    align: "center",
    label: "Initial",
    classTitle: "cell-auto",
    dataIndex: "initial",
    key: "initial",
  },
  {
    id: "gender",
    align: "center",
    label: "Gender",
    classTitle: "cell-correction",
    dataIndex: "gender",
    key: "gender",
    responsive: ["lg"],
    transformValue: (value) => {
      switch (value) {
        case "F":
          return "Female";
          break;
        case "M":
          return "Male";
          break;
        default:
          return value;
      }
    },
    sorted: true,
  },
  {
    id: "grade_id",
    align: "left",
    label: "Grade",
    classTitle: "cell-auto",
    dataIndex: "grade_name",
    key: "grade_id",
    responsive: ["lg", "md"],
    sorted: true,
  },
  {
    id: "teacher",
    align: "left",
    label: "Teacher",
    classTitle: "cell-auto",
    dataIndex: "teacher",
    key: "teacher_id",
    responsive: ["lg", "md"],
    sorted: true,
  },
];

export const PERIOD_HEAD_CELLS = [
  {
    id: "name",
    align: "left",
    label: "Term",
    classTitle: "cell-auto",
    dataIndex: "name",
    key: "name",
  },
  {
    id: "year",
    align: "left",
    label: "Year",
    classTitle: "cell-auto",
    dataIndex: "year",
    key: "year",
  },
  {
    id: "start_date",
    align: "center",
    label: "Start Date",
    classTitle: "cell-auto",
    dataIndex: "start_date",
    key: "start_date",
    responsive: ["lg"],
  },
  {
    id: "end_date",
    align: "center",
    label: "End Date",
    classTitle: "cell-correction",
    dataIndex: "end_date",
    key: "end_date",
    responsive: ["lg"],
  },
];

export const STUDENT_ATTENDANCE_HEAD_CELLS = [
  {
    id: "headshot",
    align: "center",
    label: "",
    classTitle: "cell-avatar",
    dataIndex: "headshot",
    key: "headshot",
  },
  {
    id: "firstName",
    align: "left",
    label: "First Name",
    classTitle: "cell-auto",
    dataIndex: "first_name",
    key: "first_name",
  },
  {
    id: "lastName",
    align: "left",
    label: "Last Name",
    classTitle: "cell-auto",
    dataIndex: "last_name",
    key: "last_name",
  },
  {
    id: "initial",
    align: "center",
    label: "Initial",
    classTitle: "cell-auto",
    dataIndex: "initial",
    key: "initial",
  },
  {
    id: "gender",
    align: "center",
    label: "Gender",
    classTitle: "cell-correction",
    dataIndex: "gender",
    key: "gender",
    responsive: ["lg"],
    transformValue: (value) => {
      switch (value) {
        case "F":
          return "Female";
          break;
        case "M":
          return "Male";
          break;
        default:
          return value;
      }
    },
    // sorted: true
  },
  {
    id: "attendances",
    align: "left",
    label: "Attendance Stats",
    classTitle: "cell-auto",
    dataIndex: "attendances",
    key: "attendance",
    responsive: ["lg", "md"],
    sorted: true,
    transformValue: (value) => {
      return value ? value.numOfAttendances : 0;
    },
  },
];

export const REPORT_STUDENT_HEAD_CELLS = [
  {
    id: "headshot",
    align: "center",
    label: "",
    classTitle: "cell-avatar",
    dataIndex: "headshot",
    key: "headshot",
  },
  {
    id: "firstName",
    align: "left",
    label: "First Name",
    classTitle: "cell-auto",
    dataIndex: "first_name",
    key: "first_name",
  },
  {
    id: "lastName",
    align: "left",
    label: "Last Name",
    classTitle: "cell-auto",
    dataIndex: "last_name",
    key: "last_name",
  },
  {
    id: "initial",
    align: "center",
    label: "Initial",
    classTitle: "cell-auto",
    dataIndex: "initial",
    key: "initial",
  },
  {
    id: "gender",
    align: "center",
    label: "Gender",
    classTitle: "cell-correction",
    dataIndex: "gender",
    key: "gender",
    responsive: ["lg"],
    transformValue: (value) => {
      switch (value) {
        case "F":
          return "Female";
          break;
        case "M":
          return "Male";
          break;
        default:
          return value;
      }
    },
    sorted: true,
  },
  {
    id: "Status",
    align: "left",
    label: "Status",
    classTitle: "cell-auto",
    dataIndex: "status",
    key: "status",
    responsive: ["lg", "md"],
    sorted: true,
  },
];
/*!!!!!!TABLES HEAD CELLS*/
