import React, { useState } from "react";
import ReactFilestack from "filestack-react";
import FILE_STACK_KEY from "../../config/fileStackKey";
import { makeStyles, withStyles, withTheme } from "@material-ui/core/styles";
import "./UploadPhoto.scss";
import { EmptyAvatarIcon } from "../Icons/EmptyAvatarIcon";
import { UploadIcon } from "../Icons/UploadIcon";
import clsx from "clsx";

const styles = theme => {
  return {
    uploadPicture: {
      border: `2px dashed ${theme.primary.main} `,
      color: theme.primary.main
    },
    uploaded: {
      backgroundColor: `${theme.primary.main}!important`,
      color: "#ffffff"
    }
  };
};

const useStyles = makeStyles(theme => {
  const themeColor = theme.primary.main.substring(1, theme.primary.main.length);

  return {
    uploadFieldWrapper: {
      color: theme.primary.main,
      borderRadius: 5,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23${themeColor}' stroke-width='2' stroke-dasharray='6%2c 5' stroke-dashoffset='5' stroke-linecap='square'/%3e%3c/svg%3e")`
    }
  };
});

const UploadPhoto = props => {
  const { photo, setPhoto, asButton } = props;
  const classes = useStyles();
  return (
    <React.Fragment>
      <ReactFilestack
        apikey={FILE_STACK_KEY}
        customRender={({ onPick }) => (
          <React.Fragment>
            {asButton && !photo.url && (
              <div
                className={clsx(
                  classes.uploadFieldWrapper,
                  "upload-signature-filed"
                )}
                onClick={onPick}
              >
                <div className="d-flex">
                  <UploadIcon style={{ width: 17, height: 17 }} />
                  <span className="ml-2">Upload Student Picture</span>
                </div>
              </div>
            )}

            {(!asButton || photo.url) && (
              <div className="user-profile">
                <div className="user-profile-wrapper">
                  <div className="user-profile-border">
                    <button className="btn-text" onClick={onPick}>
                      Edit
                    </button>
                    {!!(photo && photo.url) && (
                      <button
                        className="btn-text"
                        onClick={() => {
                          setPhoto({ url: "" });
                        }}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                  {photo && photo.url && <img src={photo ? photo.url : ""} />}
                  <EmptyAvatarIcon
                    style={{
                      width: "114px",
                      height: "114px",
                      background: "#fff",
                      position: "absolute"
                    }}
                  />
                </div>
              </div>
            )}
          </React.Fragment>
        )}
        onSuccess={res => {
          if (res) setPhoto(res.filesUploaded[0]);
        }}
        actionOptions={{
          exposeOriginalFile: true,
          accept: "image/*"
        }}
      />
    </React.Fragment>
  );
};

export default withStyles(styles)(withTheme(UploadPhoto));
