import React, { Component } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Logo from '../assets/images/logo_white.svg';
import { Menu } from '../constants/letMenu';
import Settings from '../assets/images/settings_icon.svg';
import LogoutIcon from '../assets/images/logout_icon.svg';
import {GetBasicInfo} from '../store/profile/profileActions';
import { signoutUser } from '../store/auth/authActions';
import { withTheme, withStyles } from '@material-ui/core/styles';
import adminIcon from '../assets/images/admin.svg';
import {EmptyAvatarIcon} from './Icons/EmptyAvatarIcon';


const styles = (theme) => ({
  leftNav: {
    background: theme.primary.main,
  },
  iconWrapperActive: {
    background: theme.primary.dark,
  },
});

class Nav extends Component {
  state = {
    isAdminVisible: false,
  };
  AdminChecked = false;

  componentDidMount() {
    this.props.GetBasicInfo();
    if (!this.AdminChecked && this.props.IsAdmin === 'true')
      this.setState({ isAdminVisible: true });

    this.AdminChecked = true;
  }

  renderMenu() {
    const { setUrl, classes, pathname } = this.props;
    const onChangeUrl = (url) => setUrl({ pathname: url });

    let menuItems = Menu.map(({ name, icon, url }) => {
      return (
        <div id={name} key={name}>
          <Link to={url} onClick={() => onChangeUrl(url)}>
            <span
              className={clsx(
                'IconWrapper',
                (pathname === url && classes.iconWrapperActive) ||
                  (pathname.includes('student') &&
                    url.includes('student') &&
                    classes.iconWrapperActive)
              )}
            >
              <img alt={name} src={icon} />
            </span>
          </Link>
        </div>
      );
    });

    if (this.state.isAdminVisible)
      menuItems.push(
        <div id="Admin" key="Admin">
          <Link
            to="/admin"
            onClick={() => {
              setUrl({ pathname: '/admin' });
            }}
          >
            <span
              className={clsx(
                'IconWrapper',
                pathname === '/admin' && classes.iconWrapperActive
              )}
            >
              <img alt="Admin" src={adminIcon} />
            </span>
          </Link>
        </div>
      );

    return menuItems;
  }

  render() {
    const { classes, setUrl, pathname, signoutUser, userProfileShow, avatar } = this.props;
    return (
      <nav id="site-nav" className={classes.leftNav}>
        <div id="Profile">
          <Link
            to="/main"
            onClick={() => {
              setUrl({ pathname: '/main' });
            }}
          >
            <span
              className={clsx(
                'IconWrapper',
                pathname === '/main' && classes.iconWrapperActive
              )}
            >
              <img alt="Profile" className="rounded-circle" src={Logo} />
            </span>
          </Link>
        </div>
        <div id="Options">{this.renderMenu()}</div>
        <div id="logout">
          <div id="Settings">
            <Link
              to="/settings"
              onClick={() => {
                setUrl({ pathname: '/settings' });
              }}
            >
              <span
                className={clsx(
                  'IconWrapper',
                  pathname === '/settings' && classes.iconWrapperActive
                )}
              >
                <img alt="Settings" src={Settings} />
              </span>
            </Link>
          </div>
          <span className="cursor-pointer IconWrapper" onClick={signoutUser}>
            <img className="icon" src={LogoutIcon} alt="Logout" />
          </span>
        </div>
        <div id="UserProfile">
          <div className="cursor-pointer IconWrapper" onClick={userProfileShow}>
            <p className="m-0">Update Profile</p>
            {avatar && <img src={avatar} alt="Avatar" />}
            <EmptyAvatarIcon style={{width: '54px', height: '54px', position: 'absolute', background: '#fff'}} />
          </div>
        </div>
      </nav>
    );
  }
}

function mapStateToProps({
  auth: { IsAdmin },
  profile: { currentUser },
}) {
  return {
    IsAdmin,
    avatar: currentUser.Avatar
  };
}

const mapActionsToProps = {
  GetBasicInfo,
  signoutUser
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(withTheme(Nav)));
