import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import Modal from 'react-modal';
import axios from 'axios';
import { ROOT_URL } from '../../config/keys';
import passwordIcon from '../../assets/images/password_reset.svg';
import './ResetPassword.scss';

export class ResetPassword extends Component {
  state = {
    isOpen: false,
    emailSent: false,
    email: '',
  };

  setModalOpenStatus = (isOpen) => () => {
    this.setState({ isOpen });
  };
  sendEmail = async (evt) => {
    evt.preventDefault();
    await axios.post(`${ROOT_URL}/requestPasswordReset`, {
      email: this.state.email,
    });

    this.setState({ emailSent: true });
  };

  render() {
    const { isOpen, emailSent, email } = this.state;
    return (
      <div>
        <div
          onClick={this.setModalOpenStatus(true)}
          className="forgotPasswordContainer"
        >
          <ReactSVG svgClassName="passwordIcon fill-info" src={passwordIcon} />
          <small className="text-graybitdarker">Forgotten Password</small>
        </div>

        <Modal
          isOpen={isOpen}
          onRequestClose={this.setModalOpenStatus(false)}
          className="Modal bg-green-light passwordModal py-5 px-4"
        >
          <div className="modalContainer ">
            <button className="close" onClick={this.setModalOpenStatus(false)}>
              <span>×</span>
            </button>
            <img
              className="passwordIcon"
              alt="Forgot password"
              src={passwordIcon}
            />
            <h5 className="mb-5 mt-3 text-white font-weight-light">
              Forgot Your Password?
            </h5>
            {emailSent ? (
              <div>
                <p class="text-white">
                  We've sent you an email with a link to reset your password
                </p>
              </div>
            ) : (
              <div>
                <input
                  value={email}
                  onChange={(evt) => this.setState({ email: evt.target.value })}
                  type="text"
                  className="w-100 py-2 pl-2 border-0"
                  placeholder="Email Address"
                />
                <button
                  disabled={!email}
                  onClick={this.sendEmail}
                  className="w-100 mt-2 bg-info text-white border-0 py-2 cursor-pointer font-weight-light"
                >
                  Reset Password
                </button>
              </div>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

export default ResetPassword;
