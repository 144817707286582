import React, {Component} from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import {Link} from "react-router-dom";

//assets

import logoIcon from '../../assets/images/logo.svg';
import BGSignIn from '../../assets/images/BG/BG_Sign_in.jpg';
//store

import { signinUser } from '../../store/auth/authActions';
import {AddErrorAlert, AddSuccessAlert} from '../../store/alert/alert';
//components

import MaterialInput from '../../components/Forms/MaterialInput/MaterialInput';
import Alert from '../../components/Alert/Alert';

//style
import './ForgotPassword.scss';
import {ROOT_URL} from "../../config/keys";
import IconButton from "@material-ui/core/IconButton";
import {Visibility, VisibilityOff} from "@material-ui/icons";

export class ForgotPassword extends Component {
  state = {
    verificationResponse: null,
    password: '',
    password_confirm: '',
    isVerifiedFetched: false,
    showPassword: false,
  };

  handleChange = (event) => {
    this.setState({ [event.currentTarget.name]: event.target.value });
  };

  handleShowPassword = () => this.setState({ showPassword: !this.state.showPassword});

  onSubmit = async (evt) => {
    evt.preventDefault();
    const { password, password_confirm } = this.state;
    const { dispatch, AddErrorAlert, AddSuccessAlert } = this.props;

    if (!password) {
      return dispatch(AddErrorAlert('Please enter new password.'));
    }
    if (!password_confirm) {
      return dispatch(AddErrorAlert('Please confirm new password.'));
    }
    if (password !== password_confirm) {
      return dispatch(AddErrorAlert('Password and confirmation must match.'));
    }
    if (password.length < 6) {
      return dispatch(AddErrorAlert('Must be at least 6 characters.'));
    }
    // this.setState({ isSigningIn: true });
    // await dispatch(signinUser(email, password));
    // this.setState({ isSigningIn: false });

    this.setState({ isVerifiedFetched: true });
    axios
    .post(`${ROOT_URL}/reset/${this.props.match.params.token}`, {newPass: password})
    .then((res) => {
      this.setState({verificationResponse: res.data});
      dispatch(AddSuccessAlert('Your password has been changed!'));
      //this.props.history.push('/');
    })
    .catch((error) => {
      this.setState({verificationResponse: error.response});
      dispatch(AddErrorAlert('No new password set.'))
    })
    .finally(() => this.setState({isVerifiedFetched: true}));
  };

  render() {
    const today = new Date();
    return (
      <div className=" Login-page">
        <Alert />
        <div className="PanelLogin">
          <div className="login-card">
            <img src={logoIcon} className="img img-fluid Logo" alt="e-TELL" />

            <h4 className="welcome">
              {this.state.verificationResponse == 1
                ?
                  'Success!'
                  :
                  'Create new password'
              }
            </h4>

            <p className="signInMsg text-graybitdarker">
              {this.state.verificationResponse == 1
                  ?
                  'Your password has been changed!'
                  :
                  'Your new password must be different from your previous used passwords.'
              }
            </p>
            <form onSubmit={this.onSubmit} style={{width: '100%', display: this.state.verificationResponse == 1 ? 'none' :'block'}}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <MaterialInput
                    id="txtPassword"
                    className="mb-2 ml-5"
                    type={this.state.showPassword ? "text" : "password"}
                    label="Create new password"
                    value={this.state.password}
                    name="password"
                    onChange={this.handleChange}
                    autoComplete="new-password"
                />
                  <IconButton
                      aria-label="toggle password visibility"
                      onClick={this.handleShowPassword}
                      onMouseDown={this.handleShowPassword}
                      style={{right: 50}}
                  >
                    {this.state.showPassword ? <Visibility/> : <VisibilityOff/>}
                  </IconButton>
              </div>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <MaterialInput
                    id="txtPasswordConfirm"
                    className="ml-5"
                    type={this.state.showPassword ? "text" : "password"}
                    label="Confirm new password"
                    value={this.state.password_confirm}
                    name="password_confirm"
                    onChange={this.handleChange}
                    autoComplete="new-password"
                />
                <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.handleShowPassword}
                    onMouseDown={this.handleShowPassword}
                    style={{right: 50}}
                >
                  {this.state.showPassword ? <Visibility/> : <VisibilityOff/>}
                </IconButton>
              </div>
              <div className="w-100 text-center mt-4">
                <button
                    type="submit"
                    disabled={this.state.isVerifiedFetched ? true : null}
                    className="btn btn-info btn-enter"
                >
                  {this.state.isVerifiedFetched ? 'Resetting Password...' : 'Reset Password'}
                </button>
              </div>
              <div className="d-flex justify-content-center w-100 py-3">
                <p className="signInMsg text-graybitdarker ">
                  <Link to={'/'}>Skip, I'll reset password later</Link>
                </p>
              </div>
            </form>
          </div>
          <div className="LoginCopyRight">
            <small>
              Copyright &copy; {today.getFullYear()}. All Rights Reserved.
              e-Tell
            </small>
          </div>
        </div>
        <div className="PanelImage">
          <img src={BGSignIn} alt="Reset Password" />
        </div>
      </div>
    );
  }
}

const actions = (dispatch) => ({
  dispatch,
  //signinUser,
  AddErrorAlert,
  AddSuccessAlert,
});

export default connect(null, actions)(ForgotPassword);
