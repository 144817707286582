import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { RemoveAlert } from '../../store/alert/alert';

export class Alert extends Component {
  onClose = (id) => () => {
    this.props.RemoveAlert(id);
  };

  renderAlerts = () => {
    const { alertList } = this.props;

    if (!alertList || !alertList.length) return null;

    return alertList.map((alt) => {
      setTimeout(this.onClose(alt.id), 3000);
      return (
        <CSSTransition
          key={alt.id}
          timeout={10000}
          classNames="fade"
          className={`alert alert-${alt.level} alert-dismissible mb-1`}
        >
          <div>
            {alt.message}
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
              onClick={this.onClose(alt.id)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </CSSTransition>
      );
    });
  };

  render() {
    return (
      <TransitionGroup
        className=" w-50 m-auto fixed-top"
        style={{ zIndex: 3000 }}
      >
        {this.renderAlerts()}
      </TransitionGroup>
    );
  }
}

const mapStateToProp = (state) => {
  return {
    alertList: state.alert.alertList,
  };
};

const actions = { RemoveAlert };

export default connect(mapStateToProp, actions)(Alert);
