import {defaultTheme} from '../../MuiThemes';
import {CHANGE_THEME} from './uiConstants';

const initialUI = {
  theme: defaultTheme,
}
export default (state = initialUI, action) => {
  if (action.type === CHANGE_THEME) {
    return {
      ...state,
      theme: action.payload,
    };
  } else { return state; }
}
