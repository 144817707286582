import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles, withTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import {CompleteUrl} from '../../utili/helper';

const availableTabs = {
  Grade: { Name: 'Grade', url: '/student/grade' },
  Student: { Name: 'Student Profile', url: '/student/dashboard' },
  Term: { Name: 'Period', url: '/student/term' },
  Subject: { Name: 'Subject', url: '/student/subject' },
  Category: { Name: 'Category', url: '/student/category' },
  AssignStudent: { Name: 'Assign Student', url: '/student/assign' },
  Attendance: { Name: 'Attendance', url: '/student/attendance' },
};
const styles = (theme) => ({
  mainPrimary: {
    color: [theme.primary.main] + '!important',
    fontWeight: 'bold',
    letterSpacing: '-0.18px',
    '&:focus': {
      color: [theme.primary.main] + '!important',
    },
  },
  hoverOverride: {
    '&:hover': {
      color: [theme.primary.main] + '!important',
      fontWeight: 'bold',
      letterSpacing: '-0.18px',
    },
  },
  underline: {
    color: theme.primary.main,
  }
});

class Header extends React.Component {
  renderTabs() {
    const { pathname, classes, setUrl } = this.props;
    const onChangeUrl = (url) => {
      setUrl({ pathname: url });
    };

    let tabList = [];
    Object.entries(availableTabs).forEach(([key, value]) => {
      tabList.push(
        <div key={key} className={'Tab py-1'}>
          <Link
            className={clsx(
              'top-menu ',
              (pathname === value.url && classes.mainPrimary) ||
                classes.hoverOverride
            )}
            onClick={() => onChangeUrl(value.url)}
            to={value.url}
          >
            {value.Name}
            {pathname === value.url ? <hr className={`under-top-menu ${classes.underline}`} /> : ''}
          </Link>
        </div>
      );
    });
    return tabList;
  }
  render() {
    const {crest, schoolName, pathname, excludedPaths} = this.props;
    const displayTabs = excludedPaths.includes(pathname);
    return (
      <div className={`header fixed-top ${!displayTabs ? '' : 'header-separator'}`}>
        <div className="text-center header-logo">
          <div className={clsx("logo-image", !crest && "empty-crest")}>
            {crest && (
                <img src={CompleteUrl(crest)} alt="crest"/>
            )}
          </div>
          <h5 className="mb-0">{schoolName ? schoolName : 'This is the College Name'}</h5>
        </div>
        {!displayTabs && <div className="TabContainer">{this.renderTabs()}</div>}
      </div>
    );
  }
}
export default withStyles(styles)(withTheme(Header));
