import { combineReducers, createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import reduxThunk from 'redux-thunk';

import { reducer as form } from 'redux-form';
import authReducer from './auth/authReducers';
import profile from './profile/profileReducers';
import teacher from './teacher/teacherReducers';
import alert from './alert/alert';
import admin from './admin/admin';
import student from './student/student';
import responsive from './responsive/responsive';
import uiReducers from './ui/uiReducers';
const rootReducer = combineReducers({
  form,
  auth: authReducer,
  profile,
  teacher,
  alert,
  admin,
  student,
  responsive,
  ui: uiReducers,
});

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(reduxThunk))
);
export default store;
