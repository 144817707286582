import React, { useEffect, useState } from "react";
import "./EmailVerify.scss";
import axios from "axios";
import { ROOT_URL } from "../../config/keys";
function EmailVerify(props) {
  const [isVerifiedFetched, setIsVerifiedFetched] = useState(false);
  const [verificationResponse, setVerificationResponse] = useState(null);
  useEffect(() => {
    axios
      .post(`${ROOT_URL}/email-update/verify/${props.match.params.token}`)
      .then((res) => setVerificationResponse(res.data))
      .catch((error) => setVerificationResponse(error.response.data))
      .finally(() => setIsVerifiedFetched(true));
  }, []);

  return (
    <div className="EmailVerify">
      <div className="OverlayGroup">
        <div className="Overlay darkGreenOverlay" />
        <div className="Overlay GreenOverlay" />
      </div>

      <div className="text-darkgray text-center mb-3">
        {!isVerifiedFetched && (
          <p className="mb-0">Please wait. Verifying...</p>
        )}
        {isVerifiedFetched &&
          verificationResponse &&
          verificationResponse.message && (
            <p
              className="mb-0"
              dangerouslySetInnerHTML={{ __html: verificationResponse.message }}
            ></p>
          )}
        {isVerifiedFetched &&
          verificationResponse &&
          verificationResponse.error && (
            <p className="mb-0 text-danger">{verificationResponse.error}</p>
          )}
      </div>
    </div>
  );
}

export default EmailVerify;
