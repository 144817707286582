import TextField from '@material-ui/core/TextField';
import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  input: {
    height: 37.5,
    boxSizing: 'border-box',
    fontSize: 14,
    fontWeight: 300,
  },
})

export const CustomTextField = (props) => {
  const styles = useStyles();
  return (
      <TextField
          InputProps={{
            classes: {input: styles.input},
            autoComplete: 'off',
          }}
          {...props}
      />
  )
}

