import { createValidator } from 'revalidate';

export const isValidEmail = createValidator(
  (message) => (value) => {
    if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      return message;
    }
  },
  'Invalid email address'
);

export function validateEmail(value) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isRequiredResult = validateRequired(value);
  if(!isRequiredResult.isValid) {
    return isRequiredResult
  }
  if(!re.test(value.trim())) {
    return {
      isValid: false,
      error: "Invalid email"
    }
  }
  return {
    isValid: true,
    error: ""
  }
}

export function validateRequired(value) {
  if(!value || typeof value === "string" && !value.trim()) {
    return {
      isValid: false,
      error: "Required"
    }
  }
  return {
    isValid: true,
    error: ""
  }
}

export function validatePassword(newPassword, confirmPassword) {
  if(newPassword !== confirmPassword) {
    return {
      isValid: false,
      error: "Passwords do not match"
    }
  }
  return {
    isValid: true,
    error: ""
  }
}

export function validateLength(value, length) {
  if(value && value.length < length) {
    return {
      isValid: false,
      error: `Must be at least ${length} characters`
    }
  }
  return {
    isValid: true,
    error: ""
  }
}
