import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import { ResponsiveMenu } from '../../constants/letMenu';
import { GetBasicInfo } from '../../store/profile/profileActions';
import { signoutUser } from '../../store/auth/authActions';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import MenuIcon from '@material-ui/icons/Menu';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import LogoutIcon from '../../assets/images/logout_icon.svg';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';

const styles = (theme) => ({
  leftNav: {
    background: theme.primary.light,
    width: '250px',
    top: '60px',
  },
  iconWrapperActive: {
    background: theme.primary.dark,
  },
  expanded: {
    '& span': {
      color: `${theme.primary.dark}!important`,
    },
  },
  expandedActive: {
    background: theme.primary.main,
    '&:hover': {
      background: theme.primary.main,
    },
  },
  buttonBurger: {
    borderRadius: '50%',
    zIndex: '1500',
    position: 'fixed',
  },
  menuBurger: {
    fill: '#ffffff',
    fontSize: '25px',
    border: '1px solid #ffffff',
    borderRadius: '5px',
    padding: '3px',
    boxSizing: 'content-box',
    display: 'block',
    margin: '7px',
  },
});

const ANCHOR = 'left';


const NavMobile = (props) => {
  const {
    setUrl,
    classes,
    pathname,
    isAdmin,
    GetBasicInfo,
    signoutUser,
  } = props;
  const [isOpen, setOpen] = useState(false);
  const [isExpanded, setExpanded] = React.useState(false);
  const [isAdminVisible, setAdminVisible] = useState(false);
  let history = useHistory();
  let AdminChecked = false;
  /*Check admin privilege */
  useEffect(() => {
    GetBasicInfo();
    if (!AdminChecked && isAdmin === 'true') setAdminVisible(true);
    AdminChecked = true;
  }, []);
  /*!!!!!!Check admin privilege */

  /*Change Drawer visibility*/
  const toggleDrawer = useCallback(
    (isNeededOpen) => (event) => {
      if (isNeededOpen === 'toggle') {
        setOpen(!isOpen);
      } else {
        setOpen(isNeededOpen);
      }
    },
    [setOpen, isOpen]
  );
  /*!!!!!Change Drawer visibility*/

  /*Change visibility of the expanded menu*/
  const changeExpanded = useCallback(() => {
    setExpanded(!isExpanded);
  }, [setExpanded, isExpanded]);
  /*!!!!!!Change visibility of the expanded menu*/

  /*Page navigation*/
  const changePage = useCallback(
    (newUrl) => (event) => {
      setUrl({ pathname: newUrl });
      setOpen(false);
      history.push(newUrl);
    },
    [setUrl]
  );
  /*!!!!!!!!Page navigation*/

  /*List data to render*/
  const list = useMemo(() => {
    return (
      <div className={classes.leftNav}>
        <List id="site-nav-responsive">
          {ResponsiveMenu.map((item) => {
            if (item.needPrivilege) {
              return isAdminVisible ? (
                <div key={item.name}>
                  <ListItem button onClick={changePage(item.url)}>
                    <ListItemIcon>
                      <span
                        className={`IconWrapper ${
                          pathname === item.url ? classes.iconWrapperActive : ''
                        }`}
                      >
                        <img alt={item.name} src={item.icon} />
                      </span>
                    </ListItemIcon>
                    <ListItemText primary={item.name} />
                  </ListItem>
                  <Divider />
                </div>
              ) : null;
            }
            return (
              <div key={item.name}>
                <ListItem
                  button
                  onClick={
                    item.expanded ? changeExpanded : changePage(item.url)
                  }
                >
                  <ListItemIcon>
                    <span
                      className={`IconWrapper ${
                        pathname !== item.url
                          ? pathname.includes('student') &&
                            item.url.includes('student')
                            ? classes.iconWrapperActive
                            : ''
                          : classes.iconWrapperActive
                      }`}
                    >
                      <img alt={item.name} src={item.icon} />
                    </span>
                  </ListItemIcon>
                  <ListItemText primary={item.name} />
                  {item.expanded ? (
                    isExpanded ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )
                  ) : (
                    ''
                  )}
                </ListItem>
                {item.expanded ? (
                  <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {item.expanded.map((expandedItem) => (
                        <ListItem
                          button
                          key={expandedItem.name}
                          className={
                            classes.expanded +
                            ' ' +
                            (pathname === expandedItem.url
                              ? classes.expandedActive
                              : '')
                          }
                          onClick={changePage(expandedItem.url)}
                        >
                          <ListItemIcon />
                          <ListItemText primary={expandedItem.name} />
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                ) : (
                  ''
                )}
                <Divider />
              </div>
            );
          })}
          <div>
            <ListItem button onClick={signoutUser}>
              <ListItemIcon>
                <span className="IconWrapper">
                  <img className="icon" src={LogoutIcon} alt="Logout" />
                </span>
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </div>
        </List>
      </div>
    );
  }, [isExpanded, ResponsiveMenu, pathname, isAdminVisible]);
  /*!!!!!!!!!List data to render*/

  return (
    <React.Fragment>
      <Button onClick={toggleDrawer('toggle')} className={classes.buttonBurger}>
        {isOpen ? (
          <CloseIcon className={classes.menuBurger} />
        ) : (
          <MenuIcon className={classes.menuBurger} />
        )}
      </Button>
      <Drawer
        anchor={ANCHOR}
        open={isOpen}
        onClose={toggleDrawer(false)}
        classes={{ paper: classes.leftNav }}
      >
        {list}
      </Drawer>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  schoolName: state.profile.currentUser.school_name,
  crest: state.profile.currentUser.Crest,
  isAdmin: state.auth.IsAdmin,
});

const mapActionsToProps = {
  GetBasicInfo,
  signoutUser,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(withTheme(NavMobile)));
