import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export function UploadIcon(props) {
  return (
      <SvgIcon viewBox="0 0 200 200" {...props}>
        <g id="Upload-Inverted" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Download-Inverted" transform="translate(6.000000, 31.000000)" fill="#4A4A4B" fillRule="nonzero">
            <path d="M130.8,68.06 C130.045666,66.3960996 128.386905,65.3280766 126.56,65.33 L108,65.33 L108,14.16 C107.972455,6.44754689 101.712502,0.21 94,0.21 L94,0.21 C86.2680135,0.21 80,6.4780135 80,14.21 L80,65.33 L61.44,65.33 C59.63199,65.3438866 57.9959523,66.4045512 57.2453475,68.049448 C56.4947427,69.6943448 56.7656776,71.6252055 57.94,73 L90.5,110.26 C91.3829896,111.269476 92.6588393,111.848546 94,111.848546 C95.3411607,111.848546 96.6170104,111.269476 97.5,110.26 L130.06,73 C131.249949,71.6390187 131.538923,69.7099217 130.8,68.06 Z" id="Path" transform="translate(94.015069, 56.029273) scale(1, -1) translate(-94.015069, -56.029273) "></path>
            <path d="M162.67,118.94 L162.67,143.94 L25.33,143.94 L25.33,118.94 C25.3244803,112.045869 19.7341332,106.46 12.84,106.46 L12.84,106.46 C5.94748632,106.46 0.36,112.047486 0.36,118.94 L0.36,156.4 C0.36,163.292514 5.94748632,168.88 12.84,168.88 L175.16,168.88 C182.052514,168.88 187.64,163.292514 187.64,156.4 L187.64,118.94 C187.64,112.047486 182.052514,106.46 175.16,106.46 L175.16,106.46 C168.265867,106.46 162.67552,112.045869 162.67,118.94 Z" id="Path"></path>
          </g>
        </g>
      </SvgIcon>
  );
}
