import axios from 'axios';

import { AUTH_USER } from './authConstants';
import { AddErrorAlert } from '../alert/alert';

import { ROOT_URL } from '../../config/keys';

export const signinUser = (email, password) => async (dispatch) => {
  //submit email/passsword to server
  try {
    const res = await axios.post(`${ROOT_URL}/signin`, { email, password });
    //if request is good, update state to indicate user is authenticated
    dispatch({
      type: AUTH_USER,
      payload: res.data,
    });

    //save the JWT token
    sessionStorage.setItem('token', res.data.token);
    sessionStorage.setItem('IsAdmin', res.data.IsAdmin);
    sessionStorage.setItem('schoolid', res.data.schools[0]._id);
    sessionStorage.setItem('theme', res.data.schools[0].theme);
    sessionStorage.setItem('permissions', res.data.permissions);
    sessionStorage.setItem('role', res.data.role);
    sessionStorage.setItem('avatar', res.data.avatar ? res.data.avatar : '');
    sessionStorage.setItem('_id', res.data._id);

    //redirect to the route/profile
    window.location = '/student/dashboard';
  } catch (error) {
    dispatch(
      AddErrorAlert(
        error.response && error.response.status === 401
          ? 'invalid credential'
          : error
      )
    );
  }
};

/*TODO need to check is this code needed or not*/

// export const signupUser = ({schoolname,email, password}, history) => async dispatch => {
//     //submit email/passsword to server
//     try {
//         const res = await axios.post(`${ROOT_URL}/signup`, {schoolname, email, password });
//         //if request is good, update state to indicate user is authenticated

//         dispatch({
//             type: AUTH_USER,
//             payload: res.data
//           });
//         //save the JWT token
//         sessionStorage.setItem('token',res.data.token);

//         //redirect to the route/feature
//         window.location = '/';
//         //history.push('/')
//     } catch (error) {
//         //if request is bad, show error
//         console.log(error);

//     }

// }

export function signoutUser() {
  sessionStorage.clear();
  window.location = '/';
  // return {
  //   type: UNAUTH_USER
  // }
}
