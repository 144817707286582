import { createMuiTheme } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import ThemeColors from './variables';
import overrides from './overrides';

export const defaultTheme = createMuiTheme({
  _name: 'defaultTheme',
  palette: {
    secondary: grey,
  },
  primary: { main: ThemeColors.colorETellGreenDefault, dark: '#364802', light: '#88a240' },
  overrides: overrides
});
export const darkBlueTheme = createMuiTheme({
  _name: 'darkBlueTheme',
  palette: {
    secondary: grey,
  },
  primary: { main: ThemeColors.colorDarkBlueThemeSelect, dark: '#061d4a', light: '#20484a' },
  overrides: overrides
});

export const mintTheme = createMuiTheme({
  _name: 'mintTheme',
  palette: {
    secondary: grey,
  },
  primary: { main: ThemeColors.colorMintThemeSelect, dark: '#2a3d3d', light: '#91b7b7' },
  overrides: overrides
});

export const darkGreenTheme = createMuiTheme({
  _name: 'darkGreenTheme',
  palette: {
    secondary: grey,
  },
  primary: { main: ThemeColors.colorGreenThemeSelect, dark: '#23403a', light: '#56988a' },
  overrides: overrides
});

export const oliveTheme = createMuiTheme({
  _name: 'oliveTheme',
  palette: {
    secondary: grey,
  },
  primary: { main: ThemeColors.colorOliveThemeSelect, dark: '#4a6350', light: '#95af94' },
  overrides: overrides
});
export const darkRedTheme = createMuiTheme({
  _name: 'darkRedTheme',
  palette: {
    secondary: grey,
  },
  primary: { main: ThemeColors.colorDarkRedThemeSelect, dark: '#49170c', light: '#852d1d' },
  overrides: overrides
});
export const muddyCherryTheme = createMuiTheme({
  _name: 'muddyCherryTheme',
  palette: {
    secondary: grey,
  },
  primary: { main: ThemeColors.colorMuddyCherryThemeSelect, dark: '#59181b', light: '#914344' },
  overrides: overrides
});
export const purpleTheme = createMuiTheme({
  _name: 'purpleTheme',
  palette: {
    secondary: grey,
  },
  primary: { main: ThemeColors.colorPurpleThemeSelect, dark: '#3e264d', light: '#8e62aa' },
  overrides: overrides
});
export const darkPinkTheme = createMuiTheme({
  _name: 'darkPinkTheme',
  palette: {
    secondary: grey,
  },
  primary: { main: ThemeColors.colorDarkPinkThemeSelect, dark: '#612c3f', light: '#c9817d' },
  overrides: overrides
});
export const peachTheme = createMuiTheme({
  _name: 'peachTheme',
  palette: {
    secondary: grey,
  },
  primary: { main: ThemeColors.colorPeachThemeSelect, dark: '#874d39', light: '#f9a483' },
  overrides: overrides
});
export const yellowTheme = createMuiTheme({
  _name: 'yellowTheme',
  palette: {
    secondary: grey,
  },
  primary: { main: ThemeColors.colorYellowThemeSelect, dark: '#57490c', light: '#d6d160' },
  overrides: overrides
});

export const ThemeVariants = [
    darkBlueTheme,
    mintTheme,
    darkGreenTheme,
    oliveTheme,
    darkRedTheme,
    muddyCherryTheme,
    purpleTheme,
    darkPinkTheme,
    peachTheme,
    yellowTheme
]
