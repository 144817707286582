import React from 'react';
import './NotFound.scss';
function NotFound() {
  return (
    <div className="NotFound">
      <div className="OverlayGroup">
        <div className="Overlay darkGreenOverlay" />
        <div className="Overlay GreenOverlay" />
      </div>
      <div className="StatusCode text-info mb-3">
        4<span className="text-primary StatusCode-Zero">0</span>4
      </div>

      <div className="text-darkgray text-center mb-3">
        <p className="mb-0">Great! You found this page.</p>
        <p>This is definitely not the page you're looking for.</p>
      </div>

      <div>
        <a href="/" className="btn btn-primary">
          GO BACK
        </a>
      </div>
    </div>
  );
}

export default NotFound;
