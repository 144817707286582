import React, { useEffect, useMemo, useRef } from 'react';
import IconButton from '@material-ui/core/IconButton';
import ReactDOM from 'react-dom';
import './modal.scss';
import CloseIcon from '../Icons/CloseIcon';

const ScrollableModal = (props) => {
  const { children, modalIsOpen, onClose, title, containerClass } = props;
  const modalRef = useRef();

  const dataToRender = useMemo(() => {
    return (
      <div>
        {modalIsOpen && (
          <div className="modal-window">
            <div className="modal-overlay" onClick={onClose} />
            <div className={`modal-container ${containerClass ? containerClass : ''}`} ref={modalRef}>
              <IconButton onClick={onClose} className="text-right">
                <CloseIcon style={{width: 40, height: 40}} className="close-modal-window" />
              </IconButton>
              {children}
            </div>
          </div>
        )}
      </div>
    );
  }, [modalIsOpen, children, title]);
  if(document.getElementById('scrollableModal')) {
    return ReactDOM.createPortal(
        dataToRender,
        document.getElementById('scrollableModal')
    );
  } else return null
};

export default ScrollableModal;
