import { GET_TEACHER, DELETE_TEACHER } from './teacherConstants';

const initState = {
  TeacherList: null,
  TeacherLoaded: false,
};

export default (state = initState, action) => {
  switch (action.type) {
    case GET_TEACHER:
      return { TeacherList: action.payload, TeacherLoaded: true };
    case DELETE_TEACHER:
      return {
        ...state,
        TeacherList: state.TeacherList.filter(
          (teacher) => teacher._id !== action.payload
        ),
      };
    default:
      return state;
  }
};
