import React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';


const CloseIcon = (props) => {
  return (
      <SvgIcon viewBox="0 0 200 200" {...props}>
        <g id="Cancel" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
          <g id="Group-36" transform="translate(37.000000, 34.000000)" stroke="#4A4A4B" strokeWidth="2">
            <line x1="124.46" y1="2.53846154" x2="2.54" y2="129.461538" id="Line"></line>
            <line x1="124.46" y1="2.53846154" x2="2.54" y2="129.461538" id="Line-Copy" transform="translate(63.500000, 66.000000) scale(-1, 1) translate(-63.500000, -66.000000) "></line>
          </g>
        </g>
      </SvgIcon>
  )
}

export default CloseIcon;
