//figure out what set of credentials to return
//process.env.PORT
if(window.location.host.includes('kps-dev')) {
  //we are on stage
  module.exports = require('./stage');
}
else if (!window.location.host.includes('localhost')) {
  //we are in dev
  module.exports = require('./prod');
}
else {
  //we are in production
  module.exports = require('./dev');
}
