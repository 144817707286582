import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './MaterialInput.scss';
export class MaterialInput extends Component {
  state = {
    focus: false,
  };

  handleOnFocus = (onFocus) => (evt) => {
    this.setState({ focus: true });
    if (typeof onFocus === 'function') onFocus(evt);
  };

  handleOnBlur = (onBlur) => (evt) => {
    this.setState({ focus: false });
    if (typeof onBlur === 'function') onBlur(evt);
  };

  render() {
    const {
      id,
      label,
      type,
      value,
      className,
      onFocus,
      onBlur,
      ...rest
    } = this.props;
    const shrinkLabel = this.state.focus || value ? 'shrink' : '';
    return (
      <div className={`MaterialInput ${className ? className : ''}`}>
        <label
          className={`MaterialInput-label text-graybitdarker ${shrinkLabel}`}
          htmlFor={id}
        >
          {label}
        </label>
        <div>
          <input
            {...rest}
            onFocus={this.handleOnFocus(onFocus)}
            onBlur={this.handleOnBlur(onBlur)}
            id={id}
            type={type}
            className="MaterialInput-input"
            value={value}
          />
        </div>
      </div>
    );
  }
}
MaterialInput.propTypes = {
  id: PropTypes.string.isRequired,
};

export default MaterialInput;
